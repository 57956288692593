import {api} from "./axiosConfig";
import Env from "../env";

interface DataApi {
  categories: () => Promise<APISection>;
  findByCategory: (category: string) => Promise<APIResponse>;
  findById: (id: number) => Promise<APIResponse>;
  getArticles: () => Promise<APIResponse>;
  getNaviInfos: (naviId: string) => Promise<APIResponse>;
  search: (data: string) => Promise<any>;
  verifyCaptcha: (token: string) => Promise<any>;
  searchByDevice: (search: string) => Promise<any>;
  searchByEmail: (search: string) => Promise<any>;
}

const URL_FAQ = "/faq";

const DataAPI: DataApi = {
  categories: async () => {
    const response = await api.request({
      baseURL: Env.API_URL,
      url: `${URL_FAQ}/categories`,
      method: "GET",
    });
    return response.data;
  },

  findByCategory: async (category: string) => {
    const response = await api.request({
      baseURL: Env.API_URL,
      url: `${URL_FAQ}/articles/category/${category}`,
      method: "GET",
    });
    return response.data;
  },

  findById: async (id: number) => {
    const response = await api.request({
      baseURL: Env.API_URL,
      url: `${URL_FAQ}/articles/category/${id}`,
      method: "GET",
    });
    return response.data;
  },

  getArticles: async () => {
    const response = await api.request({
      baseURL: Env.API_URL,
      url: `${URL_FAQ}/articles/titles`,
      method: "GET",
    });
    return response.data;
  },

  getNaviInfos: async (naviId: string) => {
    const response = await api.request({
      baseURL: Env.API_URL,
      url: `/sav/console/infos/${naviId}`,
      method: "GET",
    });
    return response.data;
  },

  search: async (data: string) => {
    const response = await api.request({
      baseURL: Env.API_URL,
      url: `${URL_FAQ}/articles/search`,
      method: "POST",
      data: {criteria: data},
    });
    return response.data;
  },

  verifyCaptcha: async (token: string) => {
    const response = await api.request({
      baseURL: Env.API_URL,
      url: `${URL_FAQ}/verify/captcha`,
      method: "POST",
      data: {token: token},
    });
    return response.data;
  },

  searchByDevice: async (search: string) => {
    const response = await api.request({
      baseURL: Env.API_URL,
      url: `/sav/user/search/device/${search}`,
      method: "GET",
    });
    return response.data;
  },

  searchByEmail: async (search: string) => {
    const response = await api.request({
      baseURL: Env.API_URL,
      url: `/sav/user/search/email/${search}`,
      method: "GET",
    });
    return response.data;
  },
};

export default DataAPI;
