import {BButton} from "./Buttons";

interface PropsBtn {
  title: string;
}

export default function BlueButton({title}: PropsBtn) {
  return (
    <a href="/">
      <BButton>{title}</BButton>
    </a>
  );
}
