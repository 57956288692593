import {useEffect, useState, Fragment, useRef} from "react";
import {useParams} from "react-router-dom";
import {
  FaApple,
  FaCheckCircle,
  FaGripLines,
  FaServer,
  FaStopCircle,
} from "react-icons/fa";
import {Transition, Dialog} from "@headlessui/react";
import {ImMobile} from "react-icons/im";
import {DiAndroid} from "react-icons/di";
import {VscWindow} from "react-icons/vsc";
import {IoHardwareChipSharp} from "react-icons/io5";
import {RiShieldUserFill} from "react-icons/ri";
import moment from "moment";
import DataAPI from "../apis/DataAPI";
import {timeDuration} from "../utils/utils";
import {MdBackspace} from "react-icons/md";
import {Link} from "react-router-dom";

interface BookItem {
  name: string;
  version: string;
}

function truncateString(
  str: string,
  maxLength: number,
  ending: string = " "
): string {
  if (str.length > maxLength) {
    return str.substring(0, maxLength - ending.length) + ending;
  }
  return str;
}

function NaviInfoPage() {
  const [data, setData] = useState<ConsoleData | null>(null);
  const [bookList, setBookList] = useState<BookItem[] | []>([]);
  const [open, setIsOpen] = useState<boolean>(false);
  const [modalInfo, setModalInfo] = useState<boolean>(false);
  const [dataModalInfo, setDataModalInfo] = useState<string>("");
  const [booksCount, setBooksCount] = useState<number>(0);
  let {id} = useParams<{id: string}>();
  let cancelButtonRef = useRef(null);
  let cancelButtonInfoRef = useRef(null);
  moment.locale();

  useEffect(() => {
    const fetchData = async () => {
      const res = await DataAPI.getNaviInfos(id ? id : "");
      if (res.status === "done") {
        setData(res.data);
        if (res?.data.current_books !== "") {
          const books = parseDataString(res.data.current_books);
          setBooksCount(res.data.current_books_count);
          setBookList(books);
        }
      }
    };
    fetchData();
  }, [id]);

  const parseDataString = (dataString: string): BookItem[] => {
    return dataString.split(",").map((item) => {
      const [name, version] = item.split(":");
      return {name, version};
    });
  };

  const closeModalInfo = () => {
    setModalInfo(false);
  };

  const openModalInfos = (v: string) => {
    setDataModalInfo(v);
    setModalInfo(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const dateFormated = (time: string) => {
    return moment(time).format("l") + " " + moment(time).format("LTS");
  };

  const dateBuild = (time: string) => {
    return moment(time).format("LL");
  };

  return (
    <div>
      <div className="bg-bblue p-4 text-white content-center items-center">
        <div className="container flex mx-auto content-center items-center">
          <div className="flex flex-col items-center ml-4">
            <Link to="/console/" className="flex-col">
              <div className="flex items-center justify-center">
                <MdBackspace className="text-2xl md:text-4xl lg:text-5xl" />
              </div>
            </Link>
          </div>
          <div className="flex-grow">
            <h1 className="text-4xl text-center font-walsheim-bold">
              Console {id?.toUpperCase()}
            </h1>
          </div>
        </div>
      </div>
      <div className="container mx-auto p-3 pb-28">
        <div className="flex flex-col">
          <div className="flex flex-col md:flex-row mb-5 md:mb-1">
            <div className="lg:w-1/2 md:w-1/2 w-full">
              <h2 className="flex items-center ml-4 text-bblue mb-5 md:mb-4 text-xl font-walsheim-regular">
                <IoHardwareChipSharp />
                <span className="ml-2">Console</span>
              </h2>
              <div className="">
                <ul className="rounded-md border-2 p-5 md:flex">
                  <div className="md:w-2/3 w-full">
                    <li>
                      <span className="font-walsheim-bold mr-2">Firmware:</span>{" "}
                      {data?.current_firmware ? data?.current_firmware : ""}
                    </li>
                    <li>
                      <span className="font-walsheim-bold mr-2">
                        Birth Date:
                      </span>{" "}
                      {data?.birth_date ? dateBuild(data?.birth_date) : ""}
                    </li>
                    <li>
                      <span className="font-walsheim-bold mr-2">
                        First Ping:
                      </span>{" "}
                      <span className="mr-2">
                        {data?.first_ping_utc
                          ? dateFormated(data?.first_ping_utc)
                          : ""}
                      </span>
                      {"/ "}
                      {data?.first_ping_utc
                        ? timeDuration(data?.first_ping_utc)
                        : ""}
                    </li>
                    <li>
                      <span className="font-walsheim-bold mr-2">
                        Last Ping:
                      </span>{" "}
                      <span className="mr-2">
                        {data?.last_ping_utc
                          ? dateFormated(data?.last_ping_utc)
                          : ""}
                      </span>
                      {"/ "}
                      {data?.last_ping_utc
                        ? timeDuration(data?.last_ping_utc)
                        : ""}
                    </li>
                    <li>
                      <span className="font-walsheim-bold mr-2">
                        Current Avatar:
                      </span>{" "}
                      {data?.current_avatar ? data?.current_avatar : ""}
                    </li>
                  </div>
                  <div className="md:w-1/3 w-full">
                    <li>
                      <span className="font-walsheim-bold mr-2">
                        SD Capacity:
                      </span>{" "}
                      {data?.sd_capacity ? data?.sd_capacity : ""}
                    </li>
                    <li className="flex items-center">
                      <span className="font-walsheim-bold mr-2">Books :</span>{" "}
                      <span className="mr-2">
                        {data?.current_books_count
                          ? data.current_books_count
                          : ""}
                      </span>
                      <VscWindow
                        className="cursor-pointer"
                        onClick={() => openModal()}
                      />
                    </li>
                    <li>
                      <span className="font-walsheim-bold mr-2">Vendor:</span>{" "}
                      {data?.vendor ? data?.vendor : ""}
                    </li>
                    <li>
                      <span className="font-walsheim-bold mr-2">Color:</span>{" "}
                      {data?.color ? data?.color : ""}
                    </li>
                  </div>
                </ul>
              </div>
            </div>
            <div className="lg:w-1/2 md:w-1/2 w-full">
              <h2 className="flex items-center ml-4 text-bblue mb-5 md:mb-4 text-xl font-walsheim-regular">
                <RiShieldUserFill />
                <span className="ml-2">Owner</span>
              </h2>
              <div className="">
                <ul className="rounded-md border-2 p-5 md:flex">
                  <div className="md:w-2/3 w-full">
                    <li>
                      <span className="font-walsheim-bold mr-2">Email:</span>{" "}
                      {data?.owner?.email ? data?.owner.email : ""}
                    </li>
                    <li>
                      <span className="font-walsheim-bold mr-2">Session:</span>{" "}
                      <span className="mr-2">
                        {data?.owner?.session_timestamp
                          ? dateFormated(data?.owner.session_timestamp)
                          : ""}
                      </span>
                      {"/ "}
                      {data?.owner?.session_timestamp
                        ? timeDuration(data?.owner.session_timestamp)
                        : ""}
                    </li>
                    <li>
                      <span className="font-walsheim-bold mr-2">
                        Active Device ID:
                      </span>{" "}
                      {data?.owner?.active_device_id
                        ? data?.owner.active_device_id
                        : ""}
                    </li>
                    <li>
                      <span className="font-walsheim-bold mr-2">Creation:</span>{" "}
                      <span className="mr-2">
                        {data?.owner?.creation_date
                          ? dateFormated(data?.owner.creation_date)
                          : ""}
                      </span>
                      {"/ "}
                      {data?.owner?.creation_date
                        ? timeDuration(data?.owner.creation_date)
                        : ""}
                    </li>
                  </div>
                  <div className="md:w-1/3 w-full">
                    <li className="flex items-center">
                      <span className="font-walsheim-bold mr-2">
                        Mail Notification:
                      </span>{" "}
                      {data?.owner && (
                        <>
                          {data?.owner?.mail_notification ? (
                            <FaCheckCircle color="green" />
                          ) : (
                            <FaStopCircle color="red" />
                          )}
                        </>
                      )}
                    </li>
                    <li className="flex items-center">
                      <span className="font-walsheim-bold mr-2">
                        Push Notification:
                      </span>{" "}
                      {data?.owner && (
                        <>
                          {data?.owner.push_notification ? (
                            <FaCheckCircle color="green" />
                          ) : (
                            <FaStopCircle color="red" />
                          )}
                        </>
                      )}
                    </li>
                    <li className="flex items-center">
                      <span className="font-walsheim-bold mr-2">Delete:</span>{" "}
                      {data?.owner && (
                        <>
                          {data?.owner.delete ? (
                            <FaCheckCircle color="blue" />
                          ) : (
                            <FaStopCircle color="green" />
                          )}
                        </>
                      )}
                    </li>
                  </div>
                </ul>
              </div>
            </div>
          </div>
          {data?.mobile_clients && data?.mobile_clients.length > 0 ? (
            <div className="flex flex-col mb-5">
              <h2 className="flex items-center ml-4 text-bblue mb-5 text-xl font-walsheim-regular">
                <ImMobile />
                <span className="ml-2">Mobile</span>
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 ml-2">
                {data?.mobile_clients.map((device: MobileClient, i: number) => {
                  const createdAt = dateFormated(device.creation_time);
                  return (
                    <div className="p-3 rounded-md border-2 bg-[#f7f7f7]">
                      <div className="flex items-center">
                        <span className="font-walsheim-bold mr-2">OS:</span>
                        {device.os_name === "ios" ? (
                          <span className="flex items-center">
                            <FaApple /> {"Apple"}
                          </span>
                        ) : (
                          <span className="flex items-center">
                            <DiAndroid /> {"Android"}
                          </span>
                        )}
                      </div>
                      <div className="">
                        <span className="font-walsheim-bold mr-2">
                          OS Version:
                        </span>{" "}
                        {device.os_version ? device.os_version : ""}
                      </div>
                      <div className="">
                        <span className="font-walsheim-bold mr-2">Device:</span>{" "}
                        {device.device ? device.device : ""}
                      </div>
                      <div className="">
                        <span className="font-walsheim-bold mr-2">
                          App Version:
                        </span>{" "}
                        {device.app_version ? device.app_version : ""}
                      </div>
                      <div className="">
                        <span className="font-walsheim-bold mr-2">Date:</span>{" "}
                        {createdAt}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <div className="flex flex-col mb-5">
              <h2 className="flex items-center ml-4 text-bblue mb-5 text-xl font-walsheim-regular">
                <ImMobile />
                <span className="ml-2">Mobile</span>
              </h2>
              <div className="ml-2">
                <p className="ml-2">There is no mobile activities</p>
              </div>
            </div>
          )}
          <div className="flex flex-col mb-5">
            <h2 className="flex items-center ml-4 text-bblue mb-5 text-xl font-walsheim-regular">
              <FaServer />
              <span className="ml-2">Server activities</span>
            </h2>
            <div className="flex flex-col">
              <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left rtl:text-right text-gray-700">
                  <thead className="text-xs uppercase bg-gray-200 text-gray-800 font-walsheim-bold">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        Event
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Param
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Data
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Date
                      </th>
                      {/* <th scope="col" className="px-6 py-3">
                        {" "}
                      </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {data?.server_activity.map((d: ServerActivity) => {
                      return (
                        <tr className="odd:bg-white even:bg-gray-50 border-b ">
                          <th
                            scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                            {d.event}
                          </th>
                          <td className="px-6 py-4 flex">
                            {truncateString(d.param ? d.param : "", 40)}
                            {d.param && d.param?.length > 40 && (
                              <VscWindow
                                className="cursor-pointer"
                                onClick={() =>
                                  openModalInfos(d.param ? d.param : "")
                                }
                              />
                            )}
                          </td>
                          <td className="px-6 py-4">{d.data}</td>
                          <td className="px-6 py-4">
                            {d.timestamp ? dateFormated(d.timestamp) : ""}
                          </td>
                          {/* <td className="px-6 py-4">
                            <a
                              href="/"
                              className="font-medium text-blue-600 hover:underline">
                              Copy
                            </a>
                          </td> */}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <h2 className="flex items-center ml-4 text-bblue mb-5 text-xl font-walsheim-regular">
              <FaGripLines />
              <span className="ml-2">Logs</span>
            </h2>
            <div className="flex flex-col">
              <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left rtl:text-right text-gray-700">
                  <thead className="text-xs uppercase bg-gray-200 text-gray-800 font-walsheim-bold">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        Class
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Name
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Params
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Date
                      </th>
                      {/* <th scope="col" className="px-6 py-3">
                        {" "}
                      </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {data?.logs.map((d: Log) => {
                      return (
                        <tr className="odd:bg-white even:bg-gray-50 border-b ">
                          <th
                            scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                            {d.class ? d.class : ""}
                          </th>
                          <td className="px-6 py-4">{d.name ? d.name : ""}</td>
                          <td className="px-6 py-4">
                            {d.param ? d.param : ""}
                          </td>
                          <td className="px-6 py-4">
                            {d.timestamp ? dateFormated(d.timestamp) : ""}
                          </td>
                          {/* <td className="px-6 py-4">
                            <a
                              href="/"
                              className="font-medium text-blue-600 hover:underline">
                              Copy
                            </a>
                          </td> */}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal Infos */}
      <Transition.Root show={modalInfo} as={"div"}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonInfoRef}
          onClose={closeModalInfo}>
          <Transition.Child
            as={"div"}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div
              className="fixed inset-0 bg-[black] bg-opacity-30 transition-opacity"
              aria-hidden="true"
            />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                        <Dialog.Title
                          as="h3"
                          className="text-xl text-bblue align-middle mb-3 font-semibold leading-6 text-gray-900">
                          Modal Infos
                        </Dialog.Title>
                        <div className="mt-2">
                          <div className="h-[300px] overflow-auto">
                            {dataModalInfo && (
                              <div className="inline">{dataModalInfo}</div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => closeModalInfo()}
                      ref={cancelButtonRef}>
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {/* Modal Books */}
      <Transition.Root show={open} as={"div"}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={closeModal}>
          <Transition.Child
            as={"div"}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div
              className="fixed inset-0 bg-[black] bg-opacity-30 transition-opacity"
              aria-hidden="true"
            />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                        <Dialog.Title
                          as="h3"
                          className="text-xl text-bblue align-middle mb-3 font-semibold leading-6 text-gray-900">
                          Books {"(" + booksCount + ")"}
                        </Dialog.Title>
                        <div className="mt-2">
                          <ul className="h-[300px] overflow-auto">
                            {bookList.length > 0 && (
                              <>
                                {bookList.map((v: BookItem) => {
                                  return (
                                    <li className="flex pb-1 items-center justify-between text-sm text-gray-500 hover:bg-[#e7e8f0]">
                                      <span>{v.name}</span>
                                      <span>{v.version}</span>
                                    </li>
                                  );
                                })}
                              </>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => closeModal()}
                      ref={cancelButtonRef}>
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

export default NaviInfoPage;
