import {styled} from "styled-components";

export const BButton = styled.button`
  margin-right: 20px;
  width: auto;
  height: 36px;
  font-size: 16px;
  line-height: 24px;
  padding: 2px 20px;
  border-radius: 12px;
  outline: 1px solid #fff;
  outline-offset: -2px;
  background-color: #0000ff;
  color: #ffffff;
  font-weight: 700;

  &:hover {
    background-color: #3333ff;
  }
`;

export const DropButton = styled.button`
  margin-right: 20px;
  transition: all 0.3s ease-out;
  width: auto;
  height: 36px;
  font-size: 16px;
  line-height: 24px;
  padding: 2px 20px;
  border-radius: 12px;
  background-color: #e7e7f2;
  color: #0000ff;
  font-weight: 700;

  &:hover {
    background-color: #dbdbf0;
  }
`;
