import {useEffect} from "react";

interface ErrorAlertProps {
  message: string;
  setErrorFile: (v: boolean) => void;
}

const ErrorAlert = ({message, setErrorFile}: ErrorAlertProps) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      setErrorFile(false);
    }, 4000);

    return () => clearTimeout(timer);
  }, [setErrorFile]);
  return (
    <div className="text-[red] rounded-lg border-1 border-[red] bg-[#dacece] p-5 mt-5">
      {message}
    </div>
  );
};

export default ErrorAlert;
