import {ChangeEvent, FormEvent, useRef, useState} from "react";
import BlueButton from "../Buttons/BlueButton";
import IconSection from "../Sections/Icons";
import EmailAPI from "../../apis/EmailAPI";
import Success from "../Alert/Success";
import {GiCancel} from "react-icons/gi";
import ErrorAlert from "../Alert/ErrorAlert";
import {MAX_TOTAL_FILE_SIZE_10} from "../../data/data";

interface ErrorState {
  [key: string]: string;
}

const BugaliQuestionForm = ({callback}: FormProps) => {
  const [form, setForm] = useState({
    email: "",
    firstname: "",
    lastname: "",
    telephone: "",
    subject: "",
    message: "",
  });
  const [files, setFiles] = useState<File[]>([]);
  const [success, setSuccess] = useState<boolean>(false);
  const [errors, setErrors] = useState<ErrorState>({});
  const [errorFile, setErrorFile] = useState<boolean>(false);
  const [captchaValue, setCaptchaValue] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [errorInvalidFile, setErrorInvalidFile] = useState<boolean>(false);

  const handleAddFileClick = () => {
    fileInputRef.current?.click();
  };

  const validateForm = () => {
    let isValid = true;
    let newErrors: ErrorState = {};

    if (!form.email || !/\S+@\S+\.\S+/.test(form.email)) {
      newErrors.email = "Veuillez entrer une adresse email valide.";
      isValid = false;
    }
    if (!form.subject) {
      newErrors.subject = "Ce champ est requis.";
      isValid = false;
    }
    if (!form.message) {
      newErrors.message = "Ce champ est requis.";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const {name, value} = e.target;
    setForm({...form, [name]: value});
    if (errors[name] && value.trim()) {
      const newErrors = {...errors};
      delete newErrors[name];
      setErrors(newErrors);
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles = e.target.files ? Array.from(e.target.files) : [];
    const totalSize =
      files.reduce((total, file) => total + file.size, 0) +
      newFiles.reduce((total, file) => total + file.size, 0);

    const validFileTypes = ["application/pdf", "image/png", "image/jpeg"];
    const invalidFiles = newFiles.filter(
      (file) => !validFileTypes.includes(file.type)
    );

    if (invalidFiles.length > 0) {
      setErrorInvalidFile(true);
      e.target.value = "";
      return;
    }

    if (totalSize > MAX_TOTAL_FILE_SIZE_10) {
      setErrorFile(true);
      e.target.value = "";
      return;
    }

    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    setErrorFile(false);
    setErrorInvalidFile(false);
  };

  const handleDeleteFile = (index: number) => {
    setFiles((currentFiles) => currentFiles.filter((_, i) => i !== index));
  };

  const sendForm = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateForm() && !errorFile) {
      const emailFormData = {
        form: "BUGALI_QUESTION",
        email: form.email,
        firstname: form.firstname,
        lastname: form.lastname,
        telephone: form.telephone,
        subject: form.subject,
        message: form.message,
        files: files,
      };
      try {
        const res = await EmailAPI.EmailContact(emailFormData);
        if (res.status === "done") {
          setSuccess(true);
        }
      } catch (error) {
        console.error("There was an error submitting the form", error);
      }
    } else {
      console.log("Form is invalid, do not send");
    }
  };

  if (success) {
    return <Success resetState={callback} />;
  }

  return (
    <form onSubmit={sendForm}>
      <div className="col-span-full">
        <div className="mt-4">
          <input
            id="email"
            name="email"
            placeholder="Adresse e-mail *"
            onChange={handleChange}
            className={`block w-full rounded-lg border-2 ${
              errors.email
                ? "border-[red] placeholder:text-[red]"
                : "border-[#B3B4D5] text-bpurple placeholder:text-gray-400"
            } hover:border-[#8e8fb4] focus:outline-none py-3 pl-2 shadow-sm focus:border-[#0000ff] sm:text-sm sm:leading-6`}
          />
        </div>
        {errors.email && <p className="italic text-[red]">{errors.email}</p>}
      </div>
      <div className="flex flex-col lg:flex-row">
        <div className="mt-4 flex-1">
          <input
            id="firstname"
            name="firstname"
            placeholder="Prénom (faculatif)"
            onChange={handleChange}
            className={`block w-full rounded-lg border-2 border-[#B3B4D5] text-bpurple placeholder:text-gray-400 hover:border-[#8e8fb4] focus:outline-none py-3 pl-2 shadow-sm focus:border-[#0000ff] sm:text-sm sm:leading-6`}
          />
        </div>
        <div className="mt-4 lg:ml-5 flex-1">
          <input
            id="lastname"
            name="lastname"
            placeholder="Nom (faculatif)"
            onChange={handleChange}
            className={`block w-full rounded-lg border-2 border-[#B3B4D5] text-bpurple placeholder:text-gray-400 hover:border-[#8e8fb4] focus:outline-none py-3 pl-2 shadow-sm focus:border-[#0000ff] sm:text-sm sm:leading-6`}
          />
        </div>
      </div>
      <div className="col-span-full">
        <div className="mt-4">
          <input
            type="text"
            name="telephone"
            id="telephone"
            placeholder="Numéro de téléphone (faculatif)"
            onChange={handleChange}
            className="block w-full rounded-lg text-bpurple border-2 border-[#B3B4D5] hover:border-[#8e8fb4] focus:outline-none py-3 pl-2 shadow-sm placeholder:text-gray-400 focus:border-[#0000ff] sm:text-sm sm:leading-6"
          />
        </div>
      </div>
      <div className="col-span-full">
        <div className="mt-4">
          <input
            type="text"
            name="subject"
            id="subject"
            placeholder="Sujet *"
            onChange={handleChange}
            className={`block w-full rounded-lg border-2 ${
              errors.subject
                ? "border-[red] placeholder:text-[red]"
                : "border-[#B3B4D5] text-bpurple placeholder:text-gray-400"
            } hover:border-[#8e8fb4] focus:outline-none py-3 pl-2 shadow-sm focus:border-[#0000ff] sm:text-sm sm:leading-6`}
          />
        </div>
      </div>
      <div className="col-span-full">
        <div className="mt-4">
          <textarea
            id="message"
            name="message"
            rows={3}
            className={`block w-full rounded-lg border-2 ${
              errors.message
                ? "border-[red] placeholder:text-[red]"
                : "border-[#B3B4D5] text-bpurple placeholder:text-gray-400"
            } hover:border-[#8e8fb4] focus:outline-none py-3 pl-2 shadow-sm focus:border-[#0000ff] sm:text-sm sm:leading-6`}
            placeholder="Message *"
            onChange={handleChange}
          />
        </div>
      </div>
      {files.length === 0 && (
        <div className="col-span-full mt-4">
          <label
            htmlFor="cover-photo"
            className="block text-sm font-medium leading-6 text-bpurple">
            Pièces jointes
          </label>
          <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-1">
            <div className="text-center">
              <div className="mt-1 flex text-sm leading-6 text-gray-600">
                <label
                  htmlFor="file-upload"
                  className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500">
                  <span className="text-bblue">Ajouter un fichier</span>
                  <input
                    id="file-upload"
                    name="file-upload"
                    type="file"
                    multiple
                    accept=".pdf,.png,.jpg,.jpeg"
                    className="sr-only"
                    onChange={handleFileChange}
                  />
                </label>
              </div>
              <p className="text-xs leading-5 text-gray-600">PDF, PNG, JPG</p>
            </div>
          </div>
        </div>
      )}
      {files.length > 0 && (
        <div className="mt-2 flex flex-wrap flex-col rounded-lg border border-dashed border-gray-900/25 px-1 py-1">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-1">
            {files.map((v: File, idx: number) => {
              const i = idx + 1;
              return (
                <div
                  className="flex content-between rounded-lg text-bblue p-3 items-center hover:bg-gray-light cursor-pointer mr-1"
                  onClick={() => handleDeleteFile(idx)}>
                  <div className="flex w-[100%]">{"#" + i + " " + v.name}</div>
                  <div className="flex">
                    <GiCancel className="ml-4 items-end" />
                  </div>
                </div>
              );
            })}
          </div>
          <div className="">
            <div
              className="rounded-lg bg-bblue hover:bg-[#3333ff] text-white p-3 text-center cursor-pointer items-center content-center"
              onClick={handleAddFileClick}>
              <span className="text-center">Ajouter un fichier</span>
            </div>
            <input
              id="file-upload"
              name="file-upload"
              type="file"
              ref={fileInputRef}
              multiple
              accept=".pdf,.png,.jpg,.jpeg"
              className="sr-only display-none"
              onChange={handleFileChange}
            />
          </div>
        </div>
      )}
      {errorFile && (
        <ErrorAlert
          message={"La taille des fichiers dépasse 10Mo."}
          setErrorFile={setErrorFile}
        />
      )}
      {errorInvalidFile && (
        <ErrorAlert
          message={
            "Le format d’une des pièces jointes n’est pas supporté. Seuls les pdf, png, et jpg sont acceptés."
          }
          setErrorFile={setErrorInvalidFile}
        />
      )}
      <p
        style={{
          padding: "5px 0px",
          border: "1px solid #dfd07c",
          borderRadius: "10px",
          marginTop: "10px",
          background: "#f9f2e4",
          color: "#ffa700",
          textAlign: "center",
        }}>
        Vous ne pouvez ajouter qu'une seule pièce jointe pour le moment.
      </p>
      <footer className="mt-10">
        {Object.keys(errors).length > 0 && (
          <div className="flex mb-4">
            <IconSection icon={"help"} color={"red"} />
            <p className="italic text-[red]">
              Attention: remplissez tous les champs obligatoires. Nous en avons
              besoin pour traiter au mieux votre demande !
            </p>
          </div>
        )}
        <BlueButton title="Envoyer" />
      </footer>
    </form>
  );
};

export default BugaliQuestionForm;
