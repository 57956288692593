import {useEffect} from "react";

declare global {
  interface Window {
    _paq: Array<Array<string | number | boolean>>;
  }
}

const MatomoTracker = () => {
  useEffect(() => {
    const _paq = (window._paq = window._paq || []);
    _paq.push(["trackPageView"]);
    _paq.push(["enableLinkTracking"]);

    (function () {
      const u = "https://matomo.bugali.io/";
      _paq.push(["setTrackerUrl", u + "piwik.php"]);
      _paq.push(["setSiteId", "7"]);

      const d = document;
      const g = d.createElement("script");
      const s = d.getElementsByTagName("script")[0];
      g.async = true;
      g.src = u + "piwik.js";
      s.parentNode!.insertBefore(g, s);
    })();
  }, []);

  return null;
};

export default MatomoTracker;
