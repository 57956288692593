import { useEffect } from "react";

const useRecaptcha = (siteKey: string) => {
  useEffect(() => {
    let script : HTMLScriptElement | null = null;

    const loadScript = () => {
      script = document.createElement("script");
      script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
      script.async = true;
      script.addEventListener('load', handleScriptLoad);
      script.addEventListener('error', handleScriptError);
      document.head.appendChild(script);
    };

    const handleScriptLoad = () => {
      console.log('reCAPTCHA script loaded successfully.');
    };

    const handleScriptError = () => {
      console.log('reCAPTCHA script failed to load. Retrying...');
      window.addEventListener('online', () => {
        if (!script) loadScript(); 
      });
    };

    loadScript();

    return () => {
      if (script) {
        script.removeEventListener('load', handleScriptLoad);
        script.removeEventListener('error', handleScriptError);
        document.head.removeChild(script);
      }
      window.removeEventListener('online', loadScript);
    };
  }, [siteKey]);
};

export default useRecaptcha;
