import {Link} from "react-router-dom";
import Bugali from "../assets/icons/bugali.svg";
import {styled} from "styled-components";

export const Item = styled.a`
  &:hover {
    text-decoration: underline;
  }
`;

export default function Footer() {
  return (
    <div className="w-full bg-white" style={{borderTop: "2px solid #0000ff"}}>
      <div className="container mx-auto p-10">
        <div className="flex flex-row justify-between text-bblue">
          <div className="basis-3/4 md:basis-1/4">
            <nav>
              <ul>
                <li className="font-bold text-lg">Liens utiles</li>
                <li>
                  <Item href="https://bugali.com/">Retour au site Bugali</Item>
                </li>
                <li>
                  <Item href="https://shop.bugali.com/">La boutique</Item>
                </li>
                <li>
                  <Item href="/contact">Contactez-nous</Item>
                </li>
              </ul>
            </nav>
          </div>
          <div className="basis-4/4">
            <Link to={"/"}>
              <img src={Bugali} alt="Bugali" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
