import IconBanner from "../Sections/IconBanner";

export default function BannerChristmas() {
  return (
    <div className="flex flex-col mb-10 w-[100%] sm:w-[90%] md:w-[90%] lg:w-[60%] mx-auto relative text-white bg-bblue rounded-[20px] pb-10 pl-10 pr-10 pt-3">
      <div className="flex flex-col md:flex-row items-center mb-4 md:mb-1">
        <IconBanner color="#fff" SizeWidth={80} SizeHeight={80} />
        <h2 className="font-walsheim-bold">Vous rencontrez un problème ?</h2>
      </div>
      <p className="mb-3">
        Téléchargez <strong>la dernière version de l'application mobile</strong>{" "}
        et assurez-vous que votre console est à jour. Si ce n'est pas le cas,
        mettez-la à jour en vous rendant dans l'onglet Console, puis en appuyant
        sur le bouton État de la mise à jour.
      </p>
      <p className="">
        Si le problème persiste, contactez notre support au{" "}
        <strong>02 46 68 00 16</strong> du lundi au vendredi (hors jours fériés)
        de 8h30 à 12h et 13h30 à 17h ou{" "}
        <a className="underline" href="https://help.bugali.com/contact#form">
          utilisez notre formulaire de contact
        </a>
        .
      </p>
    </div>
  );
}
