import React from "react";
import DOMPurify from "dompurify";

interface Props {
  content: string;
}

const SafeHtmlComponent: React.FC<Props> = ({content}) => {
  let sanitizedHtml = DOMPurify.sanitize(content, {ADD_TAGS: ["iframe"]});

  const tempContainer = document.createElement("div");
  tempContainer.innerHTML = sanitizedHtml;

  tempContainer.querySelectorAll("p").forEach((p) => {
    p.classList.add("text-bpurple", "my-4", "leading-8");
    p.querySelectorAll("img").forEach((img) => {
      img.classList.add("w-full", "h-auto");
    });
    const iframes = p.querySelectorAll("iframe");
    iframes.forEach((iframe) => {
      const wrapper = document.createElement("div");
      wrapper.className = "relative";
      wrapper.style.paddingBottom = "56.25%";

      iframe.className = "absolute top-0 left-0 w-full h-full";
      iframe.style.border = "0";
      iframe.removeAttribute("width");
      iframe.removeAttribute("height");

      // Add fullscreen attributes
      iframe.setAttribute("allowFullScreen", "");
      iframe.setAttribute("webkitAllowFullScreen", ""); // for Safari
      iframe.setAttribute("mozAllowFullScreen", ""); // for Firefox

      p.replaceChild(wrapper, iframe);
      wrapper.appendChild(iframe);
    });
  });

  sanitizedHtml = tempContainer.innerHTML;

  return <div dangerouslySetInnerHTML={{__html: sanitizedHtml}} />;
};

export default SafeHtmlComponent;
