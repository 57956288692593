import React from "react";

export const PhoneIcon: React.FC<SvgIconProps> = ({fill = "blue"}) => (
  <svg
    id="PhoneIcon"
    data-name="PhoneIcon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 900 900"
    width="64"
    height="64">
    <path
      fill={fill}
      d="m176.4,390.85c-14.82-7.25-29.6-14.6-44.32-22.05-2.16-1.09-4.54-1.57-6.94-.91-2.09.57-4.35,2.19-5.38,4.14-2.16,4.1-1.26,10.05,3.23,12.31,14.73,7.45,29.5,14.8,44.32,22.05,2.2,1.07,4.51,1.57,6.94.91,2.09-.57,4.35-2.19,5.38-4.14,2.14-4.05,1.29-10.1-3.23-12.31h0Z"
    />
    <path
      fill={fill}
      d="m174.83,456.83c-15.33,4.77-30.66,9.53-45.99,14.3-2.21.69-4.2,2.12-5.38,4.14s-1.61,4.77-.91,6.94c1.46,4.52,6.26,7.78,11.07,6.29,15.33-4.77,30.66-9.53,45.99-14.3,2.21-.69,4.2-2.12,5.38-4.14s1.61-4.77.91-6.94c-1.46-4.52-6.26-7.78-11.07-6.29h0Z"
    />
    <path
      fill={fill}
      d="m167.9,515.01c-8.34,14.36-17.62,28.17-27.76,41.32l1.41-1.82c-1.23,1.6-2.48,3.18-3.74,4.76-1.54,1.94-2.64,3.78-2.64,6.36,0,2.21.98,4.84,2.64,6.36s3.97,2.74,6.36,2.64c2.23-.1,4.91-.81,6.36-2.64,12.09-15.18,23.15-31.12,32.9-47.9,2.37-4.07,1.08-10.04-3.23-12.31s-9.79-1.12-12.31,3.23h0Z"
    />
    <path
      fill={fill}
      d="m713.19,210.97c-.22.53-.44,1.07-.67,1.6l.91-2.15c-6,14.13-13.8,27.44-23.17,39.59l1.41-1.82c-3.28,4.23-6.74,8.32-10.38,12.24-3.2,3.45-3.67,9.35,0,12.73,3.48,3.2,9.31,3.69,12.73,0,11.82-12.75,21.99-27.13,29.85-42.64,2.45-4.83,4.63-9.77,6.69-14.77.83-2.01.13-5.15-.91-6.94s-3.23-3.65-5.38-4.14c-2.3-.52-4.89-.41-6.94.91l-1.82,1.41c-1.12,1.12-1.89,2.45-2.31,3.97h0Z"
    />
    <path
      fill={fill}
      d="m740.44,350.11c12.59-.47,24.83-3.69,37.27-5.37l-2.39.32c6.35-.83,12.75-1.19,19.12-.38l-2.39-.32c.91.12,1.82.27,2.72.43,2.42.44,4.74.38,6.94-.91,1.87-1.09,3.65-3.23,4.14-5.38.52-2.3.41-4.89-.91-6.94-1.21-1.87-3.1-3.72-5.38-4.14-4.54-.83-9.14-1.29-13.75-1.22s-9.37.66-14.02,1.32c-8.06,1.14-16,2.93-24.07,4.03l2.39-.32c-3.21.43-6.43.74-9.67.86-2.36.09-4.67.94-6.36,2.64-1.56,1.56-2.74,4.12-2.64,6.36.21,4.73,3.96,9.19,9,9h0Z"
    />
    <path
      fill={fill}
      d="m753.79,401.46c15.09,5.38,30.49,9.85,46.11,13.37,4.43,1,8.88,1.92,13.34,2.76,2.41.46,4.76.37,6.94-.91,1.87-1.09,3.65-3.23,4.14-5.38.52-2.3.41-4.89-.91-6.94-1.21-1.88-3.1-3.7-5.38-4.14-15.73-2.99-31.29-6.91-46.55-11.78-4.33-1.38-8.63-2.83-12.91-4.36-2.1-.75-5.06-.19-6.94.91s-3.65,3.23-4.14,5.38c-1.15,5.05,1.53,9.38,6.29,11.07h0Z"
    />
    <path
      fill={fill}
      d="m536.48,665.98c2.44-.8,4.93-1.4,7.47-1.76l-2.39.32c3.29-.43,6.59-.46,9.88-.02l-2.39-.32c2.55.38,5.03,1.03,7.42,2.01l-2.15-.91c2.3.98,4.44,2.24,6.42,3.77l-1.82-1.41c1.75,1.39,3.33,2.97,4.7,4.74l-1.41-1.82c1.19,1.59,2.18,3.28,2.97,5.1l-.91-2.15c.75,1.82,1.25,3.7,1.52,5.65l-.32-2.39c.25,2.06.23,4.11-.03,6.17l.32-2.39c-.34,2.44-1.01,4.79-1.96,7.06l.91-2.15c-.99,2.27-2.25,4.4-3.76,6.36l1.41-1.82c-1.47,1.86-3.14,3.51-5.01,4.96l1.82-1.41c-2,1.5-4.16,2.74-6.46,3.71l2.15-.91c-2.31.94-4.71,1.6-7.18,1.95l2.39-.32c-2.46.32-4.93.33-7.39,0l2.39.32c-2.04-.3-4.02-.83-5.93-1.61l2.15.91c-1.9-.81-3.66-1.86-5.29-3.11l1.82,1.41c-1.62-1.28-3.06-2.73-4.34-4.36l1.41,1.82c-1.24-1.63-2.28-3.39-3.09-5.28l.91,2.15c-.73-1.78-1.24-3.61-1.5-5.52l.32,2.39c-.23-1.91-.18-3.79.07-5.7l-.32,2.39c.63-4.34,1.92-8.82,3.57-12.89l-.91,2.15c.58-1.36,1.26-2.66,2.14-3.85l-1.41,1.82c.61-.77,1.27-1.44,2.05-2.04l-1.82,1.41.3-.21c1.95-1.4,3.48-2.99,4.14-5.38.59-2.15.33-5.02-.91-6.94s-3.08-3.61-5.38-4.14-5-.49-6.94.91c-3.17,2.27-5.8,5.06-7.42,8.65-1.47,3.24-2.54,6.54-3.5,9.96-1.91,6.82-2.7,14.16-.17,20.94s6.83,12.29,12.76,16.49c5.35,3.79,12.26,5.62,18.78,5.56,13.41-.13,26.47-7.75,32.73-19.7,2.96-5.66,4.8-12.26,4.28-18.69-.59-7.35-3.37-13.77-8.17-19.36s-11.55-9.39-18.49-11.09c-8.28-2.02-17.16-1.47-25.22,1.19-2.22.73-4.18,2.08-5.38,4.14-1.13,1.93-1.61,4.77-.91,6.94,1.44,4.45,6.28,7.86,11.07,6.29h0Z"
    />
    <path
      fill={fill}
      d="m387.81,340.51c4.87,21.74,10.21,43.36,16.05,64.86,1.66,6.11,3.35,12.21,5.09,18.29,1.29,4.52,6.42,7.79,11.07,6.29s7.67-6.22,6.29-11.07c-6.1-21.42-11.73-42.98-16.87-64.65-1.46-6.16-2.88-12.33-4.26-18.5-1.03-4.6-6.6-7.73-11.07-6.29-4.82,1.56-7.39,6.14-6.29,11.07h0Z"
    />
    <path
      fill={fill}
      d="m398.17,345.02c12.79-4.79,26.08-8.14,39.62-9.96l-2.39.32c3.08-.41,6.27-.96,9.38-.62l-2.39-.32c.91.13,1.76.34,2.6.69l-2.15-.91c.47.22.91.48,1.34.78l-1.82-1.41c.45.35.84.73,1.21,1.16l-1.41-1.82c.41.53.74,1.08,1.02,1.69l-.91-2.15c.35.85.57,1.71.71,2.61l-.32-2.39c.16,1.3.14,2.59-.01,3.88l.32-2.39c-.31,2.26-1.01,4.43-1.89,6.52l.91-2.15c-1.06,2.42-2.4,4.68-4.02,6.78l1.41-1.82c-1.56,1.97-3.33,3.73-5.31,5.28l1.82-1.41c-.31.24-.63.47-.95.71-1.86,1.34-3.6,3.03-4.14,5.38-.46,2.01-.35,3.95.45,5.86.73,1.73,1.98,3.4,3.68,4.3,2.24,1.19,4.44,1.37,6.94.91.67-.12,1.35-.23,2.03-.32l-2.39.32c3.39-.43,6.8-.39,10.18.06l-2.39-.32c3.72.53,7.34,1.55,10.8,3.01l-2.15-.91c3.58,1.53,6.93,3.51,10.02,5.87l-1.82-1.41c2.98,2.31,5.65,4.96,7.98,7.93l-1.41-1.82c2.15,2.79,3.95,5.82,5.33,9.06l-.91-2.15c1.28,3.06,2.15,6.25,2.6,9.53l-.32-2.39c.43,3.39.4,6.79-.05,10.18l.32-2.39c-.53,3.72-1.54,7.34-2.99,10.81l.91-2.15c-1.53,3.58-3.49,6.94-5.85,10.03l1.41-1.82c-2.31,2.98-4.95,5.66-7.92,7.99l1.82-1.41c-2.79,2.15-5.81,3.95-9.05,5.34l2.15-.91c-3.18,1.33-6.48,2.2-9.89,2.66l2.39-.32c-3.53.44-7.07.44-10.6,0l2.39.32c-3.52-.48-6.94-1.39-10.23-2.75l2.15.91c-1.85-.79-3.59-1.75-5.2-2.96l1.82,1.41c-1.06-.83-2.02-1.74-2.86-2.79l1.41,1.82c-.57-.75-1.05-1.54-1.44-2.4l.91,2.15c-.31-.76-.53-1.52-.66-2.34l.32,2.39c-.04-.36-.07-.71-.08-1.07-.17-4.7-4.02-9.22-9-9s-9.18,3.96-9,9c.33,8.96,6.79,15.89,14.71,19.32s16.11,4.99,24.5,4.09c7.27-.79,14.16-2.8,20.44-6.63,5.21-3.17,9.9-7.29,13.66-12.1,4.29-5.51,7.62-11.46,9.69-18.17,1.78-5.79,2.59-12.15,1.97-18.19-.72-6.97-2.67-13.57-6.23-19.64-3.06-5.23-7.15-10.06-11.9-13.84-5.9-4.7-12.38-8.35-19.68-10.43s-15.08-2.32-22.65-.93l6.94,16.45c5.64-4.06,10.46-9.24,13.35-15.63,3.25-7.2,4.99-15.38,1.21-22.77-2.05-4-6.24-7.16-10.64-8.04-3.65-.73-7.03-.51-10.68-.06-7.34.9-14.63,2.1-21.84,3.8s-14.84,4.02-22.04,6.72c-2.25.84-4.13,2-5.38,4.14-1.13,1.93-1.61,4.77-.91,6.94s2.05,4.28,4.14,5.38,4.69,1.75,6.94.91h0Z"
    />
    <path
      fill={fill}
      d="m475.12,308.75h.83c1.24.05,2.39-.21,3.47-.78,1.12-.36,2.08-.98,2.9-1.86.88-.82,1.5-1.78,1.86-2.9.56-1.07.82-2.23.78-3.47l-.32-2.39c-.43-1.52-1.2-2.85-2.31-3.97l-1.82-1.41c-1.4-.81-2.92-1.22-4.54-1.23h-.83c-1.24-.05-2.39.21-3.47.78-1.12.36-2.08.98-2.9,1.86-.88.82-1.5,1.78-1.86,2.9-.56,1.07-.82,2.23-.78,3.47l.32,2.39c.43,1.52,1.2,2.85,2.31,3.97l1.82,1.41c1.4.81,2.92,1.22,4.54,1.23h0Z"
    />
    <path
      fill={fill}
      d="m261.66,126.71c1.72,6.91,3.44,13.82,5.16,20.73.84,3.37,1.67,6.75,2.52,10.12,1.12,4.41,3.24,8.44,7.31,10.79,4.63,2.67,9.67,1.73,14.65,1.05,3.4-.47,6.8-.94,10.2-1.43,6.79-.98,13.58-2,20.36-3.07,27.45-4.33,54.77-9.4,81.94-15.21,15.34-3.28,30.64-6.8,45.87-10.54,1.89-.46,3.51-1.72,4.68-3.23s2.12-3.56,1.93-5.45c-1.15-11.4-3.37-22.62-7.01-33.49-.74-2.22-2.07-4.17-4.14-5.38-1.93-1.13-4.77-1.61-6.94-.91-4.43,1.43-7.89,6.29-6.29,11.07,2.87,8.58,4.93,17.41,6.14,26.37l-.32-2.39c.21,1.57.39,3.15.55,4.73l6.61-8.68c-20.63,5.07-41.37,9.72-62.19,13.94-20.82,4.22-41.74,8-62.72,11.35-11.78,1.88-23.59,3.62-35.41,5.23l2.39-.32c-1.32.17-2.63.3-3.96.15l2.39.32c-.51-.09-.99-.21-1.47-.39l2.15.91c-.36-.17-.67-.35-.99-.57l1.82,1.41c-.28-.23-.52-.46-.76-.73l1.41,1.82c-.37-.47-.65-.97-.9-1.51l.91,2.15c-1-2.4-1.44-5.07-2.07-7.58-.72-2.89-1.44-5.79-2.16-8.68-1.44-5.79-2.88-11.57-4.32-17.36-1.14-4.57-6.53-7.76-11.07-6.29s-7.5,6.17-6.29,11.07h0Z"
    />
    <path
      fill={fill}
      d="m317.84,134.89v-.87c.05-1.24-.21-2.39-.78-3.47-.36-1.12-.98-2.08-1.86-2.9-.82-.88-1.78-1.5-2.9-1.86-1.07-.56-2.23-.82-3.47-.78l-2.39.32c-1.52.43-2.85,1.2-3.97,2.31l-1.41,1.82c-.81,1.4-1.22,2.92-1.23,4.54v.87c-.05,1.24.21,2.39.78,3.47.36,1.12.98,2.08,1.86,2.9.82.88,1.78,1.5,2.9,1.86,1.07.56,2.23.82,3.47.78l2.39-.32c1.52-.43,2.85-1.2,3.97-2.31l1.41-1.82c.81-1.4,1.22-2.92,1.23-4.54h0Z"
    />
    <path
      fill={fill}
      d="m343.62,136.07c4.71,0,9.22-4.14,9-9s-3.95-9-9-9c-4.71,0-9.22,4.14-9,9s3.95,9,9,9h0Z"
    />
    <path
      fill={fill}
      d="m268.3,141.13c-15.15,2.51-29.76,7.69-43.27,14.99-3.87,2.09-4.67,6.24-4.14,10.16,5.11,37.8,13.16,75.18,24.11,111.72,5.45,18.18,11.61,36.14,18.47,53.84s15.43,37.12,22.86,54.76c14.77,35.07,28.26,70.69,40.45,106.74,12.26,36.22,23.2,72.88,32.81,109.9s17.88,74.37,24.79,111.98c.85,4.63,1.68,9.27,2.49,13.91.88,5.03,6.68,7.16,11.07,6.29,41.86-8.3,83.22-19,123.85-32.05,4.48-1.44,7.83-6.31,6.29-11.07-1.45-4.48-6.27-7.83-11.07-6.29-40.63,13.05-81.99,23.75-123.85,32.05l11.07,6.29c-6.58-37.67-14.51-75.1-23.78-112.19-9.28-37.1-19.9-73.85-31.83-110.18-11.88-36.16-25.05-71.89-39.5-107.09-14.78-36.02-30.63-71.01-42.57-108.19-11.66-36.31-20.45-73.54-26.28-111.23-.72-4.64-1.39-9.3-2.02-13.95l-4.14,10.16c12.05-6.51,24.95-10.84,38.98-13.17,4.66-.77,7.67-6.78,6.29-11.07-1.63-5.04-6.08-7.11-11.07-6.29h0Z"
    />
    <path
      fill={fill}
      d="m577.49,677.16c46.31-8.5,92.62-17,138.93-25.5,4.59-.84,7.75-6.77,6.29-11.07-12-35.21-25.03-70.06-38.81-104.61-13.94-34.95-28.61-69.61-43.71-104.07s-30.15-67.68-45.56-101.38c-15.77-34.49-31.64-68.93-47.46-103.4s-31.98-69.63-47.97-104.45c-1.99-4.33-3.98-8.66-5.97-12.99-1.27-2.76-4.86-4.51-7.77-4.46-12.02.22-24,2.47-35.17,6.91-4.36,1.73-7.9,6.09-6.29,11.07,1.37,4.22,6.39,8.15,11.07,6.29,5.06-2.01,10.25-3.61,15.59-4.72,5.01-1.04,9.1-1.45,14.8-1.55l-7.77-4.46c15.91,34.65,31.83,69.3,47.74,103.95,15.81,34.43,31.65,68.85,47.43,103.29s30.79,67.4,45.76,101.28c14.97,33.88,29.13,66.93,42.95,101.08s27.07,68.91,39.26,103.86c1.52,4.37,3.03,8.75,4.52,13.13l6.29-11.07c-46.31,8.5-92.62,17-138.93,25.5-4.64.85-7.69,6.73-6.29,11.07,1.61,4.97,6.1,7.2,11.07,6.29h0Z"
    />
    <path
      fill={fill}
      d="m202.53,146.41c65.65-20.35,132.76-35.9,200.68-46.43,33.49-5.19,67.18-9.1,100.97-11.79,3.14-.25,7.5-.82,9.92-.35.45.09.71.08.98.38,1.26,1.45,2,3.61,2.79,5.33,3.66,8.01,7.18,16.08,10.61,24.19,6.57,15.5,12.8,31.14,19.09,46.75,12.41,30.76,25.44,61.22,39.46,91.29,13.24,28.41,27.07,56.55,40.81,84.73,27.52,56.45,54.83,113.18,75.82,172.45,10.34,29.18,19.13,58.93,25.6,89.21,3.42,15.99,6.37,32.16,8.02,48.44.86,8.49,1.38,17.01,1.39,25.54.01,7.41.25,15.69-1.63,22.19-.57,1.96-1.43,3.27-3.48,4.5-2.89,1.74-6.52,2.6-9.92,3.72-8.05,2.65-16.11,5.29-24.17,7.92-16.61,5.4-33.25,10.71-49.96,15.78-32.51,9.87-65.32,18.88-98.57,25.89s-66.45,11.89-100.17,13.48c-16.78.79-33.61.77-50.39-.19-4.18-.24-8.36-.52-12.53-.9-1.47-.13-2.19-.19-3.76-.59-1.7-.43-2.36-.84-3.1-1.56-1.34-1.32-2.97-4.94-3.71-6.75-1.65-4-3.22-8.04-4.73-12.09-23.14-62.11-31.94-128.46-50.29-191.98-18.24-63.16-42.09-124.49-65.31-185.93-23.35-61.77-46.2-123.93-62.08-188.11-1.74-7.04-3.91-14.23-4.93-21.42-.4-2.86-.43-5.74.99-7.85-.76,1.13,1.02-.72.27-.23-.82.54,1.63-.92,1.43-.84-1.25.49.27.03.42.04-.78-.02-.97.15-1.74-.19,4.42,2.01,9.68,1.27,12.31-3.23,2.26-3.86,1.22-10.3-3.23-12.31-8.16-3.7-17.15-1.81-23.04,5.1-6.53,7.66-6.18,17.64-4.26,26.96,3.41,16.55,7.9,32.95,12.48,49.21,9.25,32.81,20.09,65.15,31.61,97.23,23.02,64.09,48.79,127.18,70.54,191.73,10.9,32.36,20.73,65.08,28.74,98.28s14.66,66.98,23.54,100.09c4.48,16.7,9.54,33.26,15.63,49.45,2.83,7.53,5.34,16.07,10.17,22.59,5.8,7.81,14.02,9.74,23.26,10.51,35.05,2.93,70.37,1.54,105.24-2.81,70.48-8.79,138.74-28.93,206.12-50.77,8.49-2.75,16.98-5.53,25.46-8.32,7.92-2.6,17.05-4.73,22.84-11.19s6.98-15.42,7.53-23.97.52-17.94,0-26.9c-1.97-33.67-9.18-67.29-18.02-99.77-17.13-62.94-43.13-123.07-70.96-181.92-27.92-59.04-57.97-117.1-83.98-177.04-14.13-32.56-26.65-65.79-40.6-98.42-3.37-7.89-6.6-15.96-10.48-23.62-4.37-8.63-12.28-12.57-21.79-12.19-17.68.71-35.46,2.98-53.03,4.97-70.87,8.05-141.13,21.32-209.98,39.93-16.64,4.5-33.19,9.31-49.66,14.42-11.03,3.42-6.32,20.8,4.79,17.36h0Z"
    />
    <path
      fill={fill}
      d="m181.03,146.75c-17.66,10.98-25.34,32.14-24.52,52.25.49,12,3.41,23.74,6.86,35.19s6.97,22.43,10.54,33.62c14.05,44.14,28.73,88.06,43.41,131.99,14.74,44.13,29.48,88.26,43.55,132.61,14.04,44.26,27.43,88.75,39.47,133.6,11.98,44.6,22.62,89.58,31.25,134.95,1.09,5.76,2.15,11.52,3.19,17.29.64,3.59,5.04,6.9,8.68,6.61,60.69-4.88,121.29-10.97,181.74-18.28,30.48-3.69,60.93-7.68,91.33-11.97,14.34-2.02,28.73-3.91,43-6.37,12.78-2.2,25.36-5.38,37.06-11.11,27.3-13.39,39.51-41.08,50.02-67.88,1.77-4.53-1.95-9.88-6.29-11.07-5.1-1.4-9.29,1.74-11.07,6.29-8.14,20.77-17.34,43.29-37.48,54.86-10.03,5.76-21.54,8.68-33.23,10.88-13.56,2.54-27.3,4.28-40.96,6.23-58.19,8.29-116.54,15.4-175.01,21.39-33,3.38-66.04,6.39-99.1,9.05l8.68,6.61c-8.02-44.86-18.05-89.34-29.48-133.45-11.44-44.13-24.27-87.89-37.83-131.42-13.53-43.41-27.79-86.58-42.18-129.71-14.46-43.35-29.03-86.66-43.11-130.13-7.04-21.73-14.44-43.45-20.58-65.45-2.9-10.42-5.11-21.04-4.35-31.91.25-3.57,1.04-7.58,2.54-11.97,1.15-3.37,2.83-6.74,5.43-10.31,1.5-2.06,5.02-5.25,7.54-6.81,4.12-2.56,5.73-8.04,3.23-12.31s-8.17-5.81-12.31-3.23h0Z"
    />
  </svg>
);
