import {useEffect} from "react";

interface SuccessProps {
  resetState: () => void;
}

const Success = ({resetState}: SuccessProps) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      resetState();
    }, 4000);

    return () => clearTimeout(timer);
  }, [resetState]);
  return (
    <div className="text-[green] rounded-lg border-1 border-[green] bg-[#c0d1c0] p-5 mt-5">
      Votre formulaire a bien été envoyé.
    </div>
  );
};

export default Success;
