import React from "react";
import HeaderFull from "./Header/HeaderFull";
import Footer from "./Footer";

interface PropsWrapper {
  children: React.ReactNode;
}

function Wrapper({children}: PropsWrapper) {
  return (
    <div>
      <HeaderFull />
      {children}
      <Footer />
    </div>
  );
}

export default Wrapper;
