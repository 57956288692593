import React from "react";
import router from "./router";
import {RouterProvider} from "react-router-dom";
import MatomoTracker from "./components/Matomo";
import useRecaptcha from "./components/Hooks/Captcha";
import env from "./env";

function App() {
  useRecaptcha(env.CAPTCHA_KEY);
  return (
    <>
      <MatomoTracker />
      <RouterProvider router={router} fallbackElement={<p>Loading...</p>} />
    </>
  );
}

export default App;
