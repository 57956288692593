import BlueButton from "../Buttons/BlueButton";
import "../../assets/css/headerfull.css";
import BugaliLogo from "../../assets/icons/logo_header2_150x.svg";
import {useEffect, useRef, useState} from "react";
import DropDownBtn from "../Buttons/DropDownBtn";
import {FaLongArrowAltLeft} from "react-icons/fa";
import Button from "../Buttons/Button";

export default function HeaderFull() {
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);

  useEffect(() => {
    function handleDocumentClick(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownVisible(false);
      }
    }
    document.addEventListener("mousedown", handleDocumentClick);
    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
  }, []);

  return (
    <div className="w-full mb-10" style={{borderBottom: "2px solid #0000ff"}}>
      <div className="">
        <header>
          <nav>
            <div className="flex">
              <a href="/">
                <img className="logo" src={BugaliLogo} alt="Bugali" />
              </a>
              <a
                href="https://www.bugali.com"
                className="header-link ml-5 hidden md:flex lg:flex items-center">
                <FaLongArrowAltLeft className="mr-2" /> Retourner sur le site
                Bugali
              </a>
            </div>
            <div className="flex">
              <div
                className="dropdown-wrapper block md:hidden lg:hidden"
                ref={dropdownRef}>
                <DropDownBtn
                  onClick={() => setIsDropdownVisible(!isDropdownVisible)}
                  status={isDropdownVisible}
                />
                {isDropdownVisible && (
                  <div className="dropdown-content">
                    <a
                      href="https://www.bugali.com"
                      className="flex header-link w-[100%]">
                      Site Bugali
                    </a>
                    <a
                      href="https://shop.bugali.com"
                      className="flex header-link w-[100%]">
                      Boutique
                    </a>
                  </div>
                )}
              </div>
              <div className="hidden md:block lg:block">
                <Button title="Boutique" link={"https://shop.bugali.com"} />
              </div>
            </div>
          </nav>
        </header>
      </div>
    </div>
  );
}
