import styled from "styled-components";

export const Section = styled.div<{$primary?: boolean}>`
  background: #fff;
  cursor: pointer;
  border: 2px solid #bebee1;
  transition: all 0.3s ease-out;

  &:hover {
    background: #c3c3d9;
    border: 2px solid #0000ff;
  }
`;

export const Search = styled.input`
  background: #fff;
  border: 2px solid #0000ff;
  border-radius: 40px;

  &:hover {
    border: 2px solid #6a6ace;
  }
`;

export const Item = styled.li<{$selected?: boolean}>`
  cursor: pointer;
  background: ${(props) => (props.$selected ? "#0000ff" : "transparent")};
  color: ${(props) => (props.$selected ? "#fff" : "#0000ff")};
  border-radius: ${(props) => (props.$selected ? "20px" : "none")};

  &:hover {
    background: #c3c3d9;
    border-radius: 20px;
  }
`;
