import {BButton} from "./Buttons";

interface PropsBtn {
  title: string;
  link: string;
}

export default function Button({title, link}: PropsBtn) {
  return (
    <a href={link}>
      <BButton>{title}</BButton>
    </a>
  );
}
