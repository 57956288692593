import {createGlobalStyle} from "styled-components";
import GTWalsheimWoff2 from "../fonts/GT-Walsheim-Regular.woff2";
import GTWalsheimWoff from "../fonts/GT-Walsheim-Regular.woff";
import GTWalsheimTtf from "../fonts/GT-Walsheim-Regular.ttf";
import GTWalsheimMediumWoff2 from "../fonts/GT-Walsheim-Medium.woff2";
import GTWalsheimMediumWoff from "../fonts/GT-Walsheim-Medium.woff";
import GTWalsheimMediumTtf from "../fonts/GT-Walsheim-Medium.ttf";
import GTWalsheimBoldWoff2 from "../fonts/GT-Walsheim-Bold.woff2";
import GTWalsheimBoldWoff from "../fonts/GT-Walsheim-Bold.woff";
import GTWalsheimBoldTtf from "../fonts/GT-Walsheim-Bold.ttf";
import GTWalsheimUltraBoldWoff2 from "../fonts/GT-Walsheim-Ultra-Bold.woff2";
import GTWalsheimUltraBoldWoff from "../fonts/GT-Walsheim-Ultra-Bold.woff";
import GTWalsheimUltraBoldTtf from "../fonts/GT-Walsheim-Ultra-Bold.ttf";

const FontStyles = createGlobalStyle`

@font-face {
  font-family: 'GT Walsheim Regular';
  src: url(${GTWalsheimWoff2}) format('woff2'),
       url(${GTWalsheimWoff}) format('woff'),
       url(${GTWalsheimTtf}) format('truetype');
}

@font-face {
  font-family: 'GT Walsheim Medium';
  src: url(${GTWalsheimMediumWoff2}) format('woff2'),
       url(${GTWalsheimMediumWoff}) format('woff'),
       url(${GTWalsheimMediumTtf}) format('truetype');
}

@font-face {
  font-family: 'GT Walsheim Bold';
  src: url(${GTWalsheimBoldWoff2}) format('woff2'),
       url(${GTWalsheimBoldWoff}) format('woff'),
       url(${GTWalsheimBoldTtf}) format('truetype');
}

@font-face {
  font-family: 'GT Walsheim Ultra Bold';
  src: url(${GTWalsheimUltraBoldWoff2}) format('woff2'),
       url(${GTWalsheimUltraBoldWoff}) format('woff'),
       url(${GTWalsheimUltraBoldTtf}) format('truetype');
}
`;

export default FontStyles;
