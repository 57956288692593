import {FaAngleDown, FaAngleUp} from "react-icons/fa6";
import {DropButton} from "./Buttons";

interface PropsBtn {
  onClick: () => void;
  status: boolean;
}

export default function DropDownBtn({onClick, status}: PropsBtn) {
  return (
    <DropButton onClick={onClick}>
      {status ? <FaAngleUp /> : <FaAngleDown />}
    </DropButton>
  );
}
