import {useEffect, useRef, useState} from "react";
import Wrapper from "../components/Wrapper";
import DataAPI from "../apis/DataAPI";
import {Link, useLocation, useParams} from "react-router-dom";
import {Search} from "../components/Sections/Section";
import {FiSearch} from "react-icons/fi";
import {RxCross2} from "react-icons/rx";
import CaptchaForm from "../components/Forms/CaptchaForm";
import {FaLongArrowAltLeft} from "react-icons/fa";

function TestCaptchaPage() {
  const [filter, setFilter] = useState<Search[] | []>([]);
  const [search, setSearch] = useState<string>("");
  const typingTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    setSearch(e.target.value);
    typingTimeoutRef.current = setTimeout(() => {
      triggerSearch(e.target.value);
    }, 2000);
  };

  const triggerSearch = async (value: string) => {
    try {
      const response = await DataAPI.search(value);
      setFilter(response.data);
    } catch (error) {
      console.error("Error during search:", error);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current);
      }
      triggerSearch(search);
    }
  };

  useEffect(() => {
    return () => {
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current);
      }
    };
  }, []);

  const handleSearchReset = () => {
    setSearch("");
    setFilter([]);
  };

  return (
    <div>
      <Wrapper>
        <div className="container mx-auto p-8 pb-28">
          <h1 className="text-4xl mb-6 text-bblue text-center font-walsheim-bold">
            Comment pouvons-nous vous aider ?
          </h1>
          <div className="search mb-20 flex items-center w-[60%] sm:w-[90%] md:w-[90%] lg:w-[60%] mx-auto relative">
            <Search
              className="border p-5 w-full pr-10 text-lg"
              value={search ? search : ""}
              placeholder="Rechercher"
              onChange={handleSearchChange}
              onKeyDown={handleKeyDown}
            />
            <button className="p-2 absolute top-1/2 transform -translate-y-1/2 right-2">
              {search ? (
                <div onClick={handleSearchReset}>
                  <RxCross2 height={70} width={70} color="#0000ff" size={30} />
                </div>
              ) : (
                <FiSearch height={70} width={70} color="#0000ff" size={30} />
              )}
            </button>
          </div>
          {search === "" ? (
            <>
              <div className="container mx-auto p-8 pb-16">
                <div className="mb-5 text-bpurple">
                  <p>
                    <Link to={"/"}>{"Faq > "}</Link>
                    <span className="font-bold">Contactez l'équipe Bugali</span>
                  </p>
                  <div className="mt-10 text-bblue">
                    Vous n'avez pas trouvé la réponse à votre question dans
                    notre <Link to={"/"}>FAQ</Link> ? Vous avez une remarque,
                    une suggestion ? Vous voulez entrer en contact avec l'équipe
                    Bugali ? Écrivez-nous ici !
                  </div>
                  <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-6">
                    <div className="col-span-6 lg:col-span-4">
                      <CaptchaForm />
                    </div>
                  </div>
                </div>
                <div className="mb-10 mt-16 flex items-center">
                  <Link to={"/"} className="text-bblue flex items-center">
                    <FaLongArrowAltLeft className="mr-2" />
                    <span>{"Retour à la FAQ"}</span>
                  </Link>
                </div>
              </div>
            </>
          ) : (
            <>
              <h2 className="ml-4 text-bblue mb-5 text-xl font-walsheim-bold">
                {"Recherche : "}
                <span className="font-walsheim-regular">{search}</span>
              </h2>
              <div className="">
                {filter.length === 0 && (
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                    <p className="ml-4">Aucun résultat pour le moment.</p>
                  </div>
                )}
                {filter.map((article: Search) => (
                  <Link
                    to={`/${article.category}/${article.id}`}
                    key={article.id}>
                    <div className="border p-6 rounded-md">
                      <h2 className="text-bblue mb-2 text-xl font-walsheim-bold">
                        {article.title}
                      </h2>
                    </div>
                  </Link>
                ))}
              </div>
            </>
          )}
        </div>
      </Wrapper>
    </div>
  );
}

export default TestCaptchaPage;
