import {CasqueIcon} from "../../assets/icons/svg/CasqueIcon";
import {ConsoleIcon} from "../../assets/icons/svg/ConsoleIcon";
import {ContractIcon} from "../../assets/icons/svg/ContractIcon";
import {CubeIcon} from "../../assets/icons/svg/CubeIcon";
import {DoubleTouchIcon} from "../../assets/icons/svg/DoubleTouchIcon";
import {InfoIcon} from "../../assets/icons/svg/InfoIcon";
import {LegalIcon} from "../../assets/icons/svg/LegalIcon";
import {LivraisonIcon} from "../../assets/icons/svg/LivraisonIcon";
import {LivreIcon} from "../../assets/icons/svg/LivreIcon";
import {PhoneIcon} from "../../assets/icons/svg/PhoneIcon";
import {WifiIcon} from "../../assets/icons/svg/WifiIcon";

type IconKeys = keyof typeof IconList;

interface PropsIcon {
  icon: IconKeys;
  color?: string;
}

const IconList = {
  discover: DoubleTouchIcon,
  usage: ConsoleIcon,
  books: LivreIcon,
  apps: PhoneIcon,
  wifi: WifiIcon,
  account: ContractIcon,
  orders: LivraisonIcon,
  help: InfoIcon,
  legal: LegalIcon,
  headset: CasqueIcon,
  kidologie: CubeIcon,
} as const;

export default function IconSection({icon, color}: PropsIcon) {
  const IconSvg = IconList[icon] || InfoIcon;
  return <IconSvg fill={color} />;
}
