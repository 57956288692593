import {useEffect, useRef, useState} from "react";
import Wrapper from "../components/Wrapper";
import DataAPI from "../apis/DataAPI";
import {Link, useLocation, useParams} from "react-router-dom";
import {Search} from "../components/Sections/Section";
import {FiSearch} from "react-icons/fi";
import {RxCross2} from "react-icons/rx";
import NaviBreakDownForm from "../components/Forms/NaviBreakDownForm";
import AppHelpForm from "../components/Forms/AppHelpForm";
import BugaliQuestionForm from "../components/Forms/BugaliQuestionForm";
import AccountHelpForm from "../components/Forms/AccountHelpForm";
import BusinessForm from "../components/Forms/BusinessForm";
import OtherForm from "../components/Forms/OtherForm";
import OrderHelpForm from "../components/Forms/OrderHelpForm";
import {FaLongArrowAltLeft} from "react-icons/fa";
import BannerChristmas from "../components/Banner/BannerChristmas";
import AccountDeleteForm from "../components/Forms/AccountDeleteForm";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function SupportPage() {
  let query = useQuery();
  let formParam = query.get("form") || "";
  let deviceParam = query.get("device") || "";
  const [filter, setFilter] = useState<Search[] | []>([]);
  const [search, setSearch] = useState<string>("");
  const [selectedForm, setSelectedForm] = useState<string>(formParam);
  let {id} = useParams<{id: string}>();
  const typingTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      // IMPLEMENT FETCH CONSOLE ID VERIF
    };
    fetchData();
  }, [id]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    setSearch(e.target.value);
    typingTimeoutRef.current = setTimeout(() => {
      triggerSearch(e.target.value);
    }, 2000);
  };

  useEffect(() => {
    // Fonction pour gérer le défilement
    const scrollToAnchor = () => {
      const hash = window.location.hash;
      console.log(hash + " hash");
      if (hash) {
        const elmnt = document.getElementById(hash.substring(1));
        if (elmnt) {
          elmnt.scrollIntoView(true);
        }
      }
    };
    scrollToAnchor();
    window.addEventListener("hashchange", scrollToAnchor, false);
    return () => {
      window.removeEventListener("hashchange", scrollToAnchor, false);
    };
  }, []);

  const triggerSearch = async (value: string) => {
    try {
      const response = await DataAPI.search(value);
      setFilter(response.data);
    } catch (error) {
      console.error("Error during search:", error);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current);
      }
      triggerSearch(search);
    }
  };

  useEffect(() => {
    return () => {
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current);
      }
    };
  }, []);

  const resetSelectedForm = () => {
    setSelectedForm("");
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedForm(e.target.value);
  };

  const handleSearchReset = () => {
    setSearch("");
    setFilter([]);
  };

  return (
    <div>
      <Wrapper>
        <div className="container mx-auto p-8 pb-28">
          <h1 className="text-4xl mb-6 text-bblue text-center font-walsheim-bold">
            Comment pouvons-nous vous aider ?
          </h1>
          <BannerChristmas />
          <div className="search mb-20 flex items-center w-[60%] sm:w-[90%] md:w-[90%] lg:w-[60%] mx-auto relative">
            <Search
              className="border p-5 w-full pr-10 text-lg"
              value={search ? search : ""}
              placeholder="Rechercher"
              onChange={handleSearchChange}
              onKeyDown={handleKeyDown}
            />
            <button className="p-2 absolute top-1/2 transform -translate-y-1/2 right-2">
              {search ? (
                <div onClick={handleSearchReset}>
                  <RxCross2 height={70} width={70} color="#0000ff" size={30} />
                </div>
              ) : (
                <FiSearch height={70} width={70} color="#0000ff" size={30} />
              )}
            </button>
          </div>
          {search === "" ? (
            <>
              <div id="form" className="container mx-auto p-8 pb-16">
                <div className="mb-5 text-bpurple">
                  <p>
                    <Link to={"/"}>{"Faq > "}</Link>
                    <span className="font-bold">Contactez l'équipe Bugali</span>
                  </p>
                  <div className="mt-10 text-bblue">
                    Vous n'avez pas trouvé la réponse à votre question dans
                    notre <Link to={"/"}>FAQ</Link> ? Vous avez une remarque,
                    une suggestion ? Vous voulez entrer en contact avec l'équipe
                    Bugali ? Écrivez-nous ici !
                  </div>
                  <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-6">
                    <div className="col-span-6 lg:col-span-4">
                      <div className="mt-2">
                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
                          <select
                            name="selection"
                            id="selection"
                            className={`w-full border-2 rounded-lg bg-[#fff] py-3 pl-2 ${
                              selectedForm !== ""
                                ? "text-bblue"
                                : "text-[#6464AA] border-[#6464AA]"
                            } placeholder:text-[#6464AA] focus:ring-0 sm:text-sm sm:leading-6`}
                            placeholder="Motif de la demande"
                            onChange={handleSelectChange}
                            value={selectedForm}>
                            <option value="" selected>
                              Motif de la demande
                            </option>
                            <option value="ORDER_HELP">
                              J'ai une question sur ma commande ou mon paiement
                            </option>
                            <option value="ORDER_DELIVERY">
                              J'ai une question sur la livraison de ma commande
                            </option>
                            <option value="NAVI_BREAKDOWN">
                              J'ai un problème avec ma console ou un livre
                            </option>
                            <option value="APP_HELP">
                              J'ai besoin d'aide avec l'application Bugali
                            </option>
                            <option value="ACCOUNT_HELP">
                              J'ai une question sur mon compte / mes données
                              personnelles
                            </option>
                            <option value="ACCOUNT_DELETE">
                              Je souhaite supprimer mon compte
                            </option>
                            <option value="BUSINESS_CONTACT">
                              Je suis un professionnel
                            </option>
                            <option value="BUGALI_QUESTION">
                              J'ai une question sur Bugali
                            </option>
                            <option value="OTHER">
                              Autre question / Ma demande n'est pas dans la
                              liste
                            </option>
                          </select>
                        </div>
                      </div>
                      {selectedForm === "ORDER_HELP" && (
                        <OrderHelpForm callback={resetSelectedForm} />
                      )}
                      {selectedForm === "ORDER_DELIVERY" && (
                        <OrderHelpForm callback={resetSelectedForm} />
                      )}
                      {selectedForm === "NAVI_BREAKDOWN" && (
                        <NaviBreakDownForm
                          deviceId={deviceParam}
                          callback={resetSelectedForm}
                        />
                      )}
                      {selectedForm === "APP_HELP" && (
                        <AppHelpForm callback={resetSelectedForm} />
                      )}
                      {selectedForm === "ACCOUNT_HELP" && (
                        <AccountHelpForm callback={resetSelectedForm} />
                      )}
                      {selectedForm === "ACCOUNT_DELETE" && (
                        <AccountDeleteForm callback={resetSelectedForm} />
                      )}
                      {selectedForm === "BUSINESS_CONTACT" && (
                        <BusinessForm callback={resetSelectedForm} />
                      )}
                      {selectedForm === "BUGALI_QUESTION" && (
                        <BugaliQuestionForm callback={resetSelectedForm} />
                      )}
                      {selectedForm === "OTHER" && (
                        <OtherForm callback={resetSelectedForm} />
                      )}
                    </div>
                  </div>
                </div>
                <div className="mb-10 mt-16 flex items-center">
                  <Link to={"/"} className="text-bblue flex items-center">
                    <FaLongArrowAltLeft className="mr-2" />
                    <span>{"Retour à la FAQ"}</span>
                  </Link>
                </div>
              </div>
            </>
          ) : (
            <>
              <h2 className="ml-4 text-bblue mb-5 text-xl font-walsheim-bold">
                {"Recherche : "}
                <span className="font-walsheim-regular">{search}</span>
              </h2>
              <div className="">
                {filter.length === 0 && (
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                    <p className="ml-4">Aucun résultat pour le moment.</p>
                  </div>
                )}
                {filter.map((article: Search) => (
                  <Link
                    to={`/${article.category}/${article.id}`}
                    key={article.id}>
                    <div className="border p-6 rounded-md">
                      <h2 className="text-bblue mb-2 text-xl font-walsheim-bold">
                        {article.title}
                      </h2>
                    </div>
                  </Link>
                ))}
              </div>
            </>
          )}
        </div>
      </Wrapper>
    </div>
  );
}

export default SupportPage;
