import React from "react";
import {createBrowserRouter} from "react-router-dom";
import HomePage from "./pages/HomePage";
import SectionPage from "./pages/SectionPage";
import SinglePage from "./pages/SinglePage";
import NotFound from "./pages/NotFound";
import SupportPage from "./pages/SupportPage";
import NaviInfoPage from "./pages/NaviInfoPage";
import NaviSearchPage from "./pages/NaviSearchPage";
import TrackPageView from "./components/TrackPageView";
import TestCaptchaPage from "./pages/TestCaptchaPage";
import TestSupportPage from "./pages/TestSupportPage";

let router = createBrowserRouter([
  {
    path: "/",
    Component() {
      return (
        <TrackPageView>
          <HomePage />
        </TrackPageView>
      );
    },
  },
  {
    path: "/:section/",
    Component() {
      return (
        <TrackPageView>
          <SectionPage />
        </TrackPageView>
      );
    },
  },
  {
    path: "/:section/:id",
    Component() {
      return (
        <TrackPageView>
          <SinglePage />
        </TrackPageView>
      );
    },
  },
  {
    path: "/contact",
    Component() {
      return (
        <TrackPageView>
          <SupportPage />
        </TrackPageView>
      );
    },
  },
  {
    path: "/contact/:id",
    Component() {
      return (
        <TrackPageView>
          <SupportPage />
        </TrackPageView>
      );
    },
  },
  {
    path: "/console/",
    Component() {
      return <NaviSearchPage />;
    },
  },
  {
    path: "/console/infos/:id",
    Component() {
      return <NaviInfoPage />;
    },
  },
  {
    path: "/captcha/test/",
    Component() {
      return <TestCaptchaPage />;
    },
  },
  {
    path: "/404",
    Component() {
      return <NotFound />;
    },
  },
  {
    path: "*",
    Component() {
      return <NotFound />;
    },
  },
]);

export default router;
