import React from "react";
import Wrapper from "../components/Wrapper";
import BlueButton from "../components/Buttons/BlueButton";
import {Link} from "react-router-dom";

function NotFound() {
  return (
    <div>
      <Wrapper>
        <div className="container mx-auto p-8 pb-28">
          <h1 className="text-4xl mb-6 text-bblue text-center font-walsheim-bold">
            Erreur 404
          </h1>
          <div className="search mb-20 flex flex-col text-bblue items-center w-[60%] sm:w-[90%] md:w-[90%] lg:w-[60%] mx-auto relative">
            <p className="mb-10">
              La page que vous recherchez n'existe pas ou à été supprimé.
            </p>
            <Link to={"/"}>
              <BlueButton title="Home" />
            </Link>
          </div>
        </div>
      </Wrapper>
    </div>
  );
}

export default NotFound;
