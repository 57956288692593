import {api} from "./axiosConfig";
import Env from "../env";

interface DataApi {
  EmailNaviBreakdown: (formData: NaviFormData) => Promise<APISection>;
  EmailOrderHelp: (formData: OrderHelpFormData) => Promise<APISection>;
  EmailOrderInvoice: (formData: OrderInvoiceFormData) => Promise<APISection>;
  EmailBusiness: (formData: GFormData) => Promise<APISection>;
  EmailContact: (formData: GFormData) => Promise<APISection>;
  EmailDeleteAccount: (formData: AccountDeleteFormData) => Promise<APISection>;
  EmailAppHelp: (formData: AppHelpFormData) => Promise<APISection>;
}

function newFormData(formData: GFormData) {
  const form = new FormData();
  form.append("form", formData.form);
  form.append("email", formData.email);
  form.append("firstname", formData.firstname);
  form.append("lastname", formData.lastname);
  form.append("telephone", formData.telephone);
  form.append("subject", formData.subject);
  form.append("message", formData.message);
  return form;
}

const URL_FAQ = "/faq";

const EmailAPI: DataApi = {
  EmailNaviBreakdown: async (formData: NaviFormData) => {
    let data: any;
    let headers;

    if (formData.files && formData.files.length > 0) {
      data = newFormData(formData);
      data.append("sales_channel", formData.sales_channel);
      data.append("console_serial", formData.console_serial);
      formData.files.forEach((file: File, index: number) => {
        data.append(`file${index}`, file, file.name);
      });
      headers = {"Content-Type": "multipart/form-data"};
    } else {
      data = JSON.stringify(formData);
      headers = {"Content-Type": "application/json"};
    }

    const response = await api.request({
      headers: headers,
      baseURL: Env.API_URL,
      url: `${URL_FAQ}/email`,
      method: "POST",
      data: data,
    });
    return response.data;
  },

  EmailOrderHelp: async (formData: OrderHelpFormData) => {
    let data: any;
    let headers;

    if (formData.files && formData.files.length > 0) {
      data = newFormData(formData);
      data.append("sales_channel", formData.sales_channel);
      data.append("order_number", formData.order_number);
      formData.files.forEach((file: File, index: number) => {
        data.append(`file${index}`, file, file.name);
      });
      headers = {"Content-Type": "multipart/form-data"};
    } else {
      data = JSON.stringify(formData);
      headers = {"Content-Type": "application/json"};
    }
    const response = await api.request({
      headers: headers,
      baseURL: Env.API_URL,
      url: `${URL_FAQ}/email`,
      method: "POST",
      data: data,
    });
    return response.data;
  },

  EmailOrderInvoice: async (formData: OrderHelpFormData) => {
    let data: any;
    let headers;

    if (formData.files && formData.files.length > 0) {
      data = newFormData(formData);
      data.append("sales_channel", formData.sales_channel);
      data.append("order_number", formData.order_number);
      formData.files.forEach((file: File, index: number) => {
        data.append(`file${index}`, file, file.name);
      });
      headers = {"Content-Type": "multipart/form-data"};
    } else {
      data = JSON.stringify(formData);
      headers = {"Content-Type": "application/json"};
    }
    const response = await api.request({
      headers: headers,
      baseURL: Env.API_URL,
      url: `${URL_FAQ}/email`,
      method: "POST",
      data: data,
    });
    return response.data;
  },

  EmailBusiness: async (formData: GFormData) => {
    let data: any;
    let headers;

    if (formData.files) {
      data = newFormData(formData);
      formData.files.forEach((file: File, index: number) => {
        data.append(`file${index}`, file, file.name);
      });
      headers = {"Content-Type": "multipart/form-data"};
    } else {
      data = JSON.stringify(formData);
      headers = {"Content-Type": "application/json"};
    }
    const response = await api.request({
      headers: headers,
      baseURL: Env.API_URL,
      url: `${URL_FAQ}/email`,
      method: "POST",
      data: data,
    });
    return response.data;
  },

  EmailContact: async (formData: GFormData) => {
    let data: any;
    let headers;

    if (formData.files && formData.files.length > 0) {
      data = newFormData(formData);
      formData.files.forEach((file: File, index: number) => {
        data.append(`file${index}`, file, file.name);
      });
      headers = {"Content-Type": "multipart/form-data"};
    } else {
      data = JSON.stringify(formData);
      headers = {"Content-Type": "application/json"};
    }
    const response = await api.request({
      headers: headers,
      baseURL: Env.API_URL,
      url: `${URL_FAQ}/email`,
      method: "POST",
      data: data,
    });
    return response.data;
  },

  EmailAppHelp: async (formData: AppHelpFormData) => {
    let data: any;
    let headers;

    if (formData.files && formData.files.length > 0) {
      data = newFormData(formData);
      data.append("type_smartphone", formData.type_smartphone);
      data.append("console_serial", formData.console_serial);
      formData.files.forEach((file: File, index: number) => {
        data.append(`file${index}`, file, file.name);
      });
      headers = {"Content-Type": "multipart/form-data"};
    } else {
      data = JSON.stringify(formData);
      headers = {"Content-Type": "application/json"};
    }

    const response = await api.request({
      headers: headers,
      baseURL: Env.API_URL,
      url: `${URL_FAQ}/email`,
      method: "POST",
      data: data,
    });
    return response.data;
  },

  EmailDeleteAccount: async (formData: AccountDeleteFormData) => {
    let data: any;
    let headers;

    if (formData.files && formData.files.length > 0) {
      data = newFormData(formData);
      data.append("type_account", formData.type_account);
      formData.files.forEach((file: File, index: number) => {
        data.append(`file${index}`, file, file.name);
      });
      headers = {"Content-Type": "multipart/form-data"};
    } else {
      data = JSON.stringify(formData);
      headers = {"Content-Type": "application/json"};
    }

    const response = await api.request({
      headers: headers,
      baseURL: Env.API_URL,
      url: `${URL_FAQ}/email`,
      method: "POST",
      data: data,
    });
    return response.data;
  },
};

export default EmailAPI;
