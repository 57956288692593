import {useRef, useState} from "react";
import moment from "moment";
import DataAPI from "../apis/DataAPI";
import {Search} from "../components/Sections/Section";
import {FiSearch} from "react-icons/fi";
import {RxCross2} from "react-icons/rx";
import {IoIosArrowForward, IoIosArrowDown} from "react-icons/io";
import "../assets/css/search.css";

interface Results {
  id: string;
  email?: string;
}

function NaviSearchPage() {
  const [searchType, setSearchType] = useState<string>("DeviceId");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [results, setResults] = useState<Results[] | []>([]);
  const [search, setSearch] = useState<string>("");
  const selectRef = useRef<HTMLSelectElement | null>(null);
  moment.locale();

  const handleSearchChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = event.target.value;
    if (inputValue !== "" && inputValue.length >= 3) {
      let res;
      if (searchType === "DeviceId") {
        res = await DataAPI.searchByDevice(inputValue);
      } else {
        res = await DataAPI.searchByEmail(inputValue);
      }
      if (res.status === "done") {
        setResults(res.data);
      }
    } else {
      setResults([]);
    }
    setSearch(inputValue);
  };

  const handleIconClick = () => {
    if (selectRef.current === null) return;
    console.log(selectRef.current);
    selectRef.current.blur();
    selectRef.current?.focus();
    selectRef.current.click();
  };

  const handleSearchReset = () => {
    setSearch("");
    setResults([]);
  };

  const handleSearchTypeChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSearchType(event.target.value);
  };

  const redirectInfos = (id: string) => {
    const url = "/console/infos/" + id;
    window.location.href = url;
  };

  const dropdownIcon = isDropdownOpen ? (
    <div onClick={handleIconClick}>
      <IoIosArrowForward color="white" size={17} />
    </div>
  ) : (
    <div onClick={handleIconClick}>
      <IoIosArrowDown color="white" size={17} />
    </div>
  );

  return (
    <div>
      <div className="container mx-auto p-8 pb-28">
        <h1 className="text-4xl mb-6 text-bblue text-center font-walsheim-bold">
          Find console
        </h1>
        <div className="search mb-10 flex items-center w-[100%] sm:w-[90%] md:w-[90%] lg:w-[60%] mx-auto relative">
          <div className="dropdown-container">
            <select
              ref={selectRef}
              value={searchType}
              onChange={handleSearchTypeChange}
              className="search-container p-5 border rounded-[40px] text-lg bg-bblue absolute top-1/2 transform -translate-y-1/2 left-1 text-white">
              <option value="DeviceId">Device ID</option>
              <option value="Email">Email</option>
            </select>
            <div
              className="dropdown-icon cursor-pointer"
              onClick={handleIconClick}>
              {dropdownIcon}
            </div>
          </div>
          <Search
            className="border p-5 w-full pl-40 text-lg"
            value={search ? search : ""}
            placeholder="Search..."
            onFocus={() => setIsDropdownOpen(true)}
            onBlur={() => setIsDropdownOpen(false)}
            onChange={handleSearchChange}
          />
          <button className="p-2 absolute top-1/2 transform -translate-y-1/2 right-2">
            {search ? (
              <div onClick={handleSearchReset}>
                <RxCross2 height={70} width={70} color="#0000ff" size={30} />
              </div>
            ) : (
              <FiSearch height={70} width={70} color="#0000ff" size={30} />
            )}
          </button>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-x-4 gap-y-4 w-[100%] sm:w-[90%] md:w-[90%] lg:w-[60%] mx-auto">
          {results && (
            <>
              {results.map((v: Results) => {
                return (
                  <div
                    key={v.id}
                    className="cursor-pointer bg-gray-200 rounded-md p-5 w-full hover:bg-gray-100 text-bpurple"
                    onClick={() => redirectInfos(v.id)}>
                    <p>{v.id}</p>
                    {v.email && <p>{v.email}</p>}
                  </div>
                );
              })}
            </>
          )}
        </div>
        {search !== "" && search.length > 0 && results.length === 0 && (
          <div className="w-[100%] sm:w-[90%] md:w-[90%] lg:w-[60%] mx-auto">
            <p className="text-center">La console est introuvable.</p>
          </div>
        )}
        {search === "" && (
          <div className="w-[100%] sm:w-[90%] md:w-[90%] lg:w-[60%] mx-auto">
            <p className="text-center">
              Veuillez saisir au moins 3 caractères pour votre recherche.
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default NaviSearchPage;
