import React from "react";

export const LivreIcon: React.FC<SvgIconProps> = ({fill = "blue"}) => (
  <svg
    id="LivreIcon"
    data-name="LivreIcon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 900 900"
    width="64"
    height="64">
    <path
      fill={fill}
      d="m76.69,298.3c3.31,53.44,6.11,106.91,8.38,160.4,2.28,53.49,4.03,107.01,5.27,160.53.7,30.04,1.23,60.08,1.6,90.13.06,4.71,4.1,9.22,9,9s9.06-3.96,9-9c-.66-53.54-1.83-107.07-3.53-160.58-1.69-53.51-3.91-107.01-6.64-160.48-1.53-30.01-3.23-60.01-5.09-90-.29-4.69-3.93-9.23-9-9-4.63.21-9.31,3.97-9,9h0Z"
    />
    <path
      fill={fill}
      d="m108.13,718.26c22.51-39.6,62.77-66.08,104.95-81.18,49.64-17.76,105.66-24.22,156.75-8.86,26.04,7.83,50.37,21.34,69.78,40.53,4.28,4.23,10.98,3.02,14.14-1.82,2.44-3.74,4.34-6.06,8.11-9.61.86-.81,1.75-1.6,2.66-2.36.28-.24,2.44-1.9,1.31-1.07,1.92-1.41,3.89-2.73,5.93-3.95,8.22-4.92,15.27-7.41,25.06-9.76,2.45-.59,4.92-1.1,7.4-1.55,1.09-.2,2.18-.38,3.28-.56.63-.1,1.25-.19,1.88-.29,1.73-.26-2.12.25.91-.13,4.89-.61,9.81-1.01,14.73-1.25,11.37-.54,22.78-.17,34.14.48,25.71,1.47,51.23,5.21,76.3,11.1,12.76,3,25.39,6.53,37.84,10.64,10.08,3.32,19.45,6.86,28.54,11.16,21.09,9.97,40.14,23.26,52.2,42.64,2.57,4.12,8.04,5.73,12.31,3.23s5.81-8.17,3.23-12.31c-25.7-41.27-75.34-58.92-120.14-70.45-26.87-6.91-54.42-11.38-82.09-13.47-23.37-1.76-47.15-2.3-70.25,2.28-23.38,4.64-45.58,15.73-58.9,36.14l14.14-1.82c-39.65-39.2-96.08-55.96-150.94-54.24-28.73.9-57.55,6.25-84.87,15.12-23.55,7.64-46.46,18.08-66.94,32.06-23.08,15.74-43.14,35.82-57.01,60.2-2.4,4.23-1,9.84,3.23,12.31s9.9,1.01,12.31-3.23h0Z"
    />
    <path
      fill={fill}
      d="m776.73,707.65c1.43-31.9,2.88-63.81,6.14-95.59.42-4.07.87-8.15,1.35-12.21.22-1.89.46-3.77.69-5.66.11-.87.22-1.74.34-2.61.06-.43.11-.87.17-1.3-.21,1.62.05-.35.09-.65,1.07-7.82,2.29-15.62,3.67-23.39,2.65-14.91,5.98-29.69,9.86-44.32,7.55-28.48,17.01-56.4,26.42-84.3s18.83-55.9,26.24-84.44,12.79-57.87,14.12-87.42c.16-3.61.26-7.23.3-10.85.05-4.71-4.18-9.22-9-9s-8.94,3.95-9,9c-.08,7.36-.41,14.71-1,22.04-.29,3.58-.63,7.16-1.03,10.74-.19,1.72-.4,3.44-.61,5.15-.06.47-.41,3.12-.14,1.17-.13.92-.25,1.84-.39,2.76-2.06,14.36-4.93,28.6-8.36,42.69-6.92,28.49-16.05,56.38-25.38,84.15s-19.02,55.8-26.97,84.18c-8.51,30.36-14.37,61.21-18.07,92.52s-5.44,63.63-6.9,95.51c-.18,3.95-.36,7.9-.54,11.85-.21,4.71,4.29,9.21,9,9,5.06-.23,8.77-3.95,9-9h0Z"
    />
    <path
      fill={fill}
      d="m92.24,299.55c15.59-57.76,65.65-99.98,119.1-122.48,59.86-25.2,127.66-27.65,189.1-6.69,33.17,11.32,64.12,29.32,90.32,52.59,3.89,3.46,8.79,3.26,12.73,0,41.69-34.54,93.74-58.79,147.94-64,50.32-4.84,100.95,7.13,142.91,35.83,22.52,15.4,41.59,35.63,55.16,59.31,5.76,10.05,21.32.99,15.54-9.08-26.58-46.39-72.26-79.48-122.96-95.08-54.24-16.68-113.26-11.65-165.64,9.18-31.18,12.4-59.86,29.72-85.68,51.12h12.73c-50.85-45.18-117.43-71.48-185.56-72.15-67.62-.66-136.89,23.23-187.25,68.89-26.02,23.58-46.59,53.67-55.8,87.78-3.02,11.2,14.34,15.97,17.36,4.79h0Z"
    />
    <path
      fill={fill}
      d="m418.94,617.68c-.02-1.31.02-2.62.13-3.93.03-.29.06-.58.09-.87.11-1.12.02.16-.05.29.28-.58.25-1.52.38-2.15.27-1.28.6-2.56,1-3.81.18-.56.37-1.11.57-1.65.1-.27.21-.54.31-.82.47-1.26-.46.94.08-.18.57-1.18,1.14-2.36,1.81-3.49.28-.48.57-.95.87-1.41.14-.22.3-.43.44-.65.69-1.05-.78.8.03-.02.23-.23.44-.48.67-.71l.14-.14c.39-.33.38-.34-.02-.03l-.3.23c.21-.15.43-.29.65-.42.76-.58-.42.24-.36.08.1-.24,1.53-.29,1.53-.42-.01.33-1.36.05-.41.09.4.02.81.02,1.21.02,1.34.02-1.01-.25-.18-.02.26.07.54.11.81.18.61.15,1.2.36,1.79.56s.35.43-.28-.13c.15.13.55.25.75.35.56.28,1.11.58,1.65.89.94.55,1.79,1.29,2.73,1.81-.1-.05-1.13-.91-.35-.27.19.15.38.31.56.46.46.39.92.8,1.36,1.21.79.75,1.55,1.55,2.26,2.38.16.18.31.37.47.56.64.76-.19-.26-.26-.36.34.5.72.98,1.07,1.48.61.91,1.18,1.84,1.71,2.81.23.43.46.86.67,1.3.11.22.21.44.31.66.1.22.2.44.3.67l-.32-.76c.35,1.16.85,2.28,1.18,3.45s.56,2.36.79,3.56-.12-1.19-.04-.33c.04.36.08.72.11,1.09.05.61.08,1.21.09,1.82.03,1.28-.04,2.55-.17,3.82-.33,3.13,2.99,6.13,6,6,3.53-.16,5.65-2.64,6-6,1.16-11.08-3.48-22.67-11.41-30.38-4.08-3.96-9.48-7.59-15.18-8.52-3.94-.64-8.1-.24-11.39,2.22-2.8,2.09-4.83,4.87-6.49,7.93-3.55,6.58-5.42,14.03-5.32,21.52.04,3.14,2.73,6.15,6,6s6.05-2.64,6-6h0Z"
    />
    <path
      fill={fill}
      d="m419.18,572.56c.53-2.14,1.09-4.27,1.75-6.37.32-1.01.66-2.01,1.03-3,.16-.44.34-.87.51-1.31.44-1.15-.47,1.06-.13.31.14-.32.28-.63.43-.95.43-.93.9-1.85,1.42-2.73s1.25-1.74,1.74-2.65c-.69,1.27-.58.72-.23.31.18-.2.35-.42.53-.62.29-.32.59-.64.89-.94.38-.38.79-.72,1.2-1.08s.6-.15-.45.35c.26-.12.55-.39.8-.55.41-.26.83-.51,1.26-.74.55-.29,1.9-.61.22-.15.52-.14,1.02-.37,1.55-.51.31-.08.61-.16.92-.22l.37-.07c.65-.11.5-.09-.47.06.28.14,1.16-.08,1.5-.09.57,0,1.13.02,1.7.07,1,.09.2.36-.28-.05.08.07.69.12.75.14.63.13,1.24.28,1.86.47.11.03,1.82.63,1.82.64-.02.04-1.39-.64-.36-.14.23.11.47.22.7.34,1.17.58,2.26,1.28,3.34,2.01.93.63-.26-.24-.28-.24.17,0,.61.49.75.62.44.38.87.77,1.29,1.17.45.44.89.89,1.31,1.36s1.25,1.16,1.49,1.75c-.05-.12-.81-1.1-.17-.22.25.34.5.67.74,1.02.4.57.79,1.16,1.16,1.75.79,1.25,1.53,2.53,2.23,3.82,1.44,2.67,2.71,5.43,3.98,8.18s5.67,3.79,8.21,2.15c2.95-1.9,3.56-5.15,2.15-8.21-2.41-5.23-4.87-10.49-8.36-15.1-4.08-5.38-8.99-9.54-15.36-11.96-6-2.28-12.64-1.95-18.39.85-4.73,2.3-8.43,6.63-10.9,11.19-2.75,5.05-4.43,10.63-5.8,16.18-.75,3.04.97,6.65,4.19,7.38s6.58-.93,7.38-4.19h0Z"
    />
    <path
      fill={fill}
      d="m426.08,526.22c.4-1.8.91-3.58,1.56-5.31.09-.25.19-.49.29-.74.06-.16.33-.81,0-.01-.37.89.26-.55.34-.73.4-.83.82-1.65,1.28-2.46s.94-1.59,1.45-2.36c.22-.33.44-.65.67-.98.15-.21.31-.43.47-.64.51-.71-.2.08-.22.27.03-.3.67-.82.88-1.07.3-.35.6-.7.92-1.04.62-.68,1.27-1.34,1.95-1.97s1.37-1.23,2.09-1.82c.85-.71-.9.63.07-.05.48-.33.95-.66,1.45-.96.25-.15.51-.28.76-.42.13-.07.81-.4.07-.05-.92.43.9-.21.9-.2-.08.09-1.18.01-.56.1.09.01.55.05.62,0-.03.02-1.29-.08-.39.01.97.1-.44.04-.38-.17.02-.05,1.55,1.05.68.38-.75-.58.43.42.57.55.72.68,1.4,1.41,2.08,2.13,1.45,1.53,2.86,3.1,4.24,4.7.78.91,1.56,1.84,2.31,2.78.38.47.75.94,1.13,1.41.08.1.55.71.09.11-.54-.71.33.44.36.47,1.45,1.92,2.85,3.89,4.19,5.89,1.75,2.6,5.37,3.98,8.21,2.15,2.59-1.67,4.03-5.42,2.15-8.21-2.85-4.23-5.93-8.32-9.22-12.22-1.55-1.84-3.16-3.64-4.81-5.39s-3.44-3.66-5.59-4.91c-2.92-1.7-6.45-2.13-9.69-1.14-2.9.89-5.24,2.57-7.57,4.44-3.53,2.82-6.54,6.33-9,10.12-2.78,4.27-4.82,9.15-5.91,14.13-.67,3.06.92,6.64,4.19,7.38,3.03.69,6.66-.91,7.38-4.19h0Z"
    />
    <path
      fill={fill}
      d="m432.88,477.85c-.05-.88-.05-1.75.03-2.63.12-1.34-.24,1.08,0,.04.1-.45.18-.91.3-1.36s.25-.9.41-1.34c.09-.26.58-1.52.2-.59-.4.98.18-.33.29-.55.24-.47.5-.93.78-1.37.22-.35.44-.69.68-1.03.1-.15.33-.39.41-.57-.04.1-.93,1.12-.28.39.58-.66,1.17-1.31,1.82-1.91.3-.28.61-.54.93-.81.75-.64-.29.24-.38.29.19-.1.39-.28.57-.41.77-.53,1.57-1,2.41-1.41.16-.08.32-.15.47-.22.69-.34-1.19.45-.47.2.38-.14.76-.28,1.15-.41.44-.14.89-.26,1.35-.36.17-.04.34-.07.51-.11,1-.2.04.05-.38.02.79.06,1.65-.13,2.46-.11.46.01.94.11,1.39.1-.09,0-1.55-.26-.56-.07.23.04.46.08.68.13.85.18,1.69.42,2.51.72,1.24.45-.91-.47.02,0,.47.23.95.46,1.41.71.72.4,1.39.86,2.07,1.32.63.43-.96-.79-.38-.3.13.11.27.22.41.34.4.34.79.7,1.17,1.07s.74.75,1.08,1.15c.11.13.22.27.34.4.48.54-.78-1.11-.19-.24s1.1,1.64,1.56,2.53c.11.21.21.42.31.63.21.45.25,1.11-.12-.31.11.44.32.88.46,1.32s.25.9.35,1.35c.05.23.09.46.13.69.24,1.12-.15-1.43-.03-.2.16,1.63.56,3.05,1.76,4.24,1.04,1.04,2.75,1.82,4.24,1.76,2.99-.13,6.33-2.65,6-6-.53-5.45-2.57-10.18-6.09-14.37-3.12-3.72-7.51-6.48-12.13-7.92s-9.96-1.43-14.51.3c-5.07,1.92-9.17,5.12-12.39,9.46s-5.04,10.01-4.73,15.45c.18,3.13,2.63,6.15,6,6,3.1-.14,6.19-2.64,6-6h0Z"
    />
    <path
      fill={fill}
      d="m435.89,428.43c.43-1.77.89-3.53,1.43-5.27.26-.83.53-1.66.83-2.47.14-.38.28-.76.43-1.14.08-.22.17-.43.26-.64.45-1.14-.44.98-.13.32.77-1.63,1.55-3.23,2.56-4.73.21-.31.48-.61.66-.93-.82,1.45-.33.42.07-.03.56-.64,1.17-1.2,1.79-1.77.68-.63-.41.32-.48.36.17-.09.35-.25.51-.36.34-.23.7-.45,1.06-.66.32-.18.66-.32.98-.5.65-.34-1.27.4-.55.24.25-.06.51-.18.76-.26.43-.13.87-.23,1.31-.33,1.04-.25-1.47.11-.02,0,.4-.03.81-.05,1.21-.04.31,0,2,.16.9.02s.47.12.7.18c.38.1.76.21,1.14.33.22.07.43.16.64.23.93.32-.41-.14-.48-.21.32.3,1.01.49,1.4.71.78.43,1.51.94,2.25,1.44.47.32.25.52-.34-.28.12.16.44.35.57.46.38.32.75.64,1.12.97,1.69,1.54,3.21,3.26,4.65,5.04.22.27.61.9-.23-.31.15.21.31.41.46.61.34.46.68.92,1.01,1.39.68.97,1.33,1.95,1.97,2.94,1.69,2.64,5.41,3.96,8.21,2.15s3.59-5.17,2.15-8.21c-.63-1.34-1.27-2.68-1.9-4.02-1.35-2.84-5.65-3.8-8.21-2.15-2.93,1.89-3.59,5.17-2.15,8.21.63,1.34,1.27,2.68,1.9,4.02l10.36-6.06c-5.03-7.85-11.92-16.62-21.49-18.88-5.03-1.19-10.48-.48-14.91,2.26-3.89,2.41-6.9,6.08-8.99,10.11-2.3,4.43-3.8,9.24-4.97,14.07-.74,3.05.96,6.65,4.19,7.38s6.59-.93,7.38-4.19h0Z"
    />
    <path
      fill={fill}
      d="m452.13,376.77c-.21-.35-.39-.72-.58-1.08-.44-.81.23.53.24.6-.03-.17-.15-.39-.2-.56-.13-.38-.23-.76-.33-1.15-.04-.19-.08-.39-.12-.58-.22-.98.1,1.3.07.56-.04-.8-.09-1.58-.04-2.38,0-.15.03-.3.04-.45.05-.98-.1.71-.1.7-.08-.4.2-1.09.3-1.48s.21-.78.34-1.17c.18-.54.81-1.6-.06,0,.39-.72.7-1.47,1.13-2.18.21-.35.45-.68.68-1.03.51-.77-.34.43-.38.48.14-.14.26-.32.39-.48.57-.66,1.19-1.26,1.83-1.85.99-.91-1.13.75-.01,0,.34-.23.67-.45,1.02-.66.3-.18.62-.35.93-.51.17-.09.38-.16.55-.26-1.33.81-.87.35-.3.16.78-.27,1.6-.35,2.38-.58.03,0-1.37.12-.72.11.23,0,.46-.03.7-.04.52-.02,1.04-.01,1.56.02.19.01.48.08.69.06-1.18.11-1.13-.18-.57-.06.45.09.9.18,1.34.3s.88.26,1.31.42c.21.08.43.18.64.25-.44-.13-1.19-.53-.33-.11s1.64.88,2.4,1.41c1.18.81-.74-.66.05,0,.45.38.89.76,1.31,1.17.38.37.74.75,1.09,1.15.15.18.3.36.45.54.48.55-.78-1.1-.19-.23s1.16,1.76,1.63,2.71c.07.14.18.5.3.64-.35-.84-.44-1.05-.27-.62.07.16.13.33.19.49.18.49.33,1,.47,1.51s.16,1.06.33,1.54c-.03-.09-.14-1.55-.08-.55.02.35.05.7.07,1.05.13,3.13,2.66,6.15,6,6,3.14-.14,6.14-2.64,6-6-.46-10.81-8.74-21.43-19.7-23.28-5.35-.9-10.94-.14-15.42,3.09-4.23,3.06-7.31,7.16-8.97,12.13-1.77,5.32-1.27,11.39,1.6,16.23,1.6,2.7,5.47,3.92,8.21,2.15s3.87-5.32,2.15-8.21h0Z"
    />
    <path
      fill={fill}
      d="m460.2,320.55c-.03-.89.12-1.79.09-2.67,0-.02-.23,1.31-.08.66.04-.18.07-.37.1-.55.1-.49.22-.98.36-1.46.12-.42.26-.84.41-1.25.47-1.3-.41.8.04-.14.38-.81.78-1.6,1.24-2.37s.96-1.55,1.48-2.29c.22-.32.51-.62.7-.95-.77,1.35-.02.03.29-.34,1.19-1.44,2.46-2.81,3.78-4.14s2.62-2.64,4.02-3.87c.11-.1.62-.45.64-.54,0,.03-.93.66-.38.29.4-.27.79-.56,1.21-.8.17-.1.37-.18.54-.29.5-.35-.44.19-.53.19.13,0,.58-.18.74-.24.88-.35-.34.21-.48.02.01.02.8-.02.74,0,0,0-1.32-.19-.67-.04s-.53-.24-.54-.25c.57.29.15.41-.29-.31.11.19.6.7.79.8-.6-.31-.62-.88-.24-.3.14.22.28.43.42.65,1.17,1.88,2.22,3.84,3.33,5.76,2.66,4.62,5.31,9.25,7.97,13.87,1.56,2.72,5.5,3.9,8.21,2.15s3.83-5.3,2.15-8.21c-2.66-4.62-5.31-9.25-7.97-13.87s-5.3-10.88-11.19-12.02c-6.23-1.2-11.07,2.75-15.25,6.81-3.31,3.21-6.46,6.65-9,10.52-2.93,4.46-4.8,9.77-4.61,15.15.11,3.13,2.68,6.15,6,6s6.11-2.64,6-6h0Z"
    />
    <path
      fill={fill}
      d="m462.15,281.02c.26,1.91.07.34.04-.14s-.03-.93-.02-1.39.05-.92.09-1.38c.04-.44.27-.97-.08.56.05-.22.07-.46.12-.68.17-.91.41-1.81.7-2.69.15-.44.35-.87.48-1.31-.03.1-.63,1.35-.19.47.13-.26.25-.52.38-.78.4-.78.84-1.53,1.33-2.25.13-.19.27-.38.4-.57.54-.81-.23.3-.31.38.36-.38.67-.81,1.02-1.2.63-.69,1.3-1.33,2.01-1.93,1-.85-.83.57.02-.03.47-.33.95-.66,1.44-.96.35-.21.7-.41,1.06-.59.19-.1,1.7-.77.69-.36s.55-.18.74-.25c.5-.16,1-.31,1.51-.43.44-.1.91-.14,1.35-.26-.08.02-1.58.16-.58.09.17-.01.34-.03.51-.04.96-.07,1.92-.06,2.88.02.19.02.47.08.67.07-.08,0-1.56-.27-.59-.07.44.09.89.16,1.33.27.88.21,1.75.47,2.6.79.2.07.43.19.63.25-1.34-.36-.91-.4-.5-.2s.82.4,1.22.61c.85.46,1.67.98,2.46,1.54.14.1.27.21.41.3-1.43-.98-.47-.35-.15-.08.39.33.76.68,1.13,1.04s.71.73,1.05,1.11c.15.17.29.35.44.52.67.79-.62-.88-.21-.27.57.85,1.13,1.69,1.59,2.6.1.2.19.41.3.61.34.65-.49-1.32-.14-.34.19.54.38,1.07.54,1.62.88,3.01,4.26,5.2,7.38,4.19s5.14-4.16,4.19-7.38c-2.82-9.6-11.51-17.25-21.42-18.81-5.55-.87-10.92-.15-15.95,2.43-4.48,2.3-8.3,6.15-10.83,10.48-1.42,2.42-2.54,5.15-3.11,7.9-.69,3.35-.67,6.39-.21,9.75.19,1.38,1.62,2.92,2.76,3.59,1.29.75,3.18,1.07,4.62.6s2.85-1.37,3.59-2.76c.81-1.53.84-2.93.6-4.62h0Z"
    />
    <path
      fill={fill}
      d="m467.72,241.7c-.08-.22-.16-.44-.22-.67-.14-.64-.13-.44.03.59.05-.32.05-1.02,0-1.33-.16,1.04-.18,1.25-.05.62.05-.22.11-.43.19-.64.27-1.04-.68,1.17-.07.19.16-.25.71-.91.73-1.19-.59.74-.68.86-.27.37.16-.18.32-.36.49-.53.3-.31.63-.58.94-.87.34-.29.18-.17-.49.36.2-.15.4-.29.61-.43.42-.27.84-.53,1.29-.76.22-.12.44-.22.67-.33-.87.35-1.01.41-.41.18.79-.27,1.58-.49,2.41-.64,1.67-.31-.96.04.12-.02.4-.02.8-.06,1.21-.07.87-.02,1.72.04,2.59.1,1.08.08-1.55-.28.12.04.34.06.68.14,1.02.22.9.22,1.77.52,2.65.82,1,.34-1.34-.63.16.08.31.15.63.3.93.47.82.43,1.61.92,2.37,1.45.19.13.38.27.56.41l-.67-.51c.22.33.75.63,1.05.9.68.63,1.29,1.31,1.91,2,.83.93-.78-1.15-.09-.12.26.38.52.76.77,1.15.49.79.9,1.61,1.28,2.46.56,1.28,2.3,2.4,3.59,2.76,1.44.4,3.35.22,4.62-.6s2.41-2.06,2.76-3.59c.38-1.67.08-3.06-.6-4.62-6.27-14.33-27.91-21.21-39.7-9.45-3.7,3.69-5.85,9.27-4.04,14.4,1.04,2.95,4.15,5.24,7.38,4.19,2.91-.94,5.31-4.21,4.19-7.38h0Z"
    />
  </svg>
);
