import IconCasque from "../../assets/icons/sections/picto_casque.svg";
import IconConsole from "../../assets/icons/sections/picto_console.svg";
import IconContrat from "../../assets/icons/sections/picto_contrat.svg";
import IconDoubleTouch from "../../assets/icons/sections/picto_doubletouch.svg";
import IconInfo from "../../assets/icons/sections/picto_information_2.svg";
import IconLegal from "../../assets/icons/sections/picto_légal.svg";
import IconLivraison from "../../assets/icons/sections/picto_livraison.svg";
import IconBook from "../../assets/icons/sections/picto_livre.svg";
import IconPhone from "../../assets/icons/sections/picto_téléphone.svg";
import IconWifi from "../../assets/icons/sections/picto_wifi.svg";
import IconCube from "../../assets/icons/sections/picto_cube.svg";

type IconKeys = keyof typeof IconList;

interface PropsIcon {
  icon: IconKeys;
  color?: string;
}
const IconList = {
  discover: IconDoubleTouch,
  usage: IconConsole,
  books: IconBook,
  apps: IconPhone,
  wifi: IconWifi,
  account: IconContrat,
  orders: IconLivraison,
  help: IconInfo,
  legal: IconLegal,
  headset: IconCasque,
  kidologie: IconCube,
} as const;

export default function IconHome({icon, color}: PropsIcon) {
  const iconSrc = IconList[icon] || IconInfo;
  const style = color ? {color: color} : {};

  return (
    <span style={style}>
      <img src={iconSrc} height={64} width={64} alt={icon} />
    </span>
  );
}
