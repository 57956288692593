import {ReactNode, useEffect} from "react";

interface TrackPageViewProps {
  children: ReactNode;
}

const TrackPageView = ({children}: TrackPageViewProps) => {
  useEffect(() => {
    var _paq = (window._paq = window._paq || []);
    _paq.push([
      "setCustomUrl",
      window.location.pathname + window.location.search,
    ]);
    _paq.push(["trackPageView"]);
  }, []);

  return <>{children}</>;
};

export default TrackPageView;
