import {ExclamationIcon} from "../../assets/icons/svg/ExclamationIcon";

interface PropsIcon {
  color?: string;
  SizeWidth?: number;
  SizeHeight?: number;
}

export default function IconBanner({color, SizeWidth, SizeHeight}: PropsIcon) {
  return (
    <div className="w-[80px] sm:w-[120px] md:w-[80px]">
      <ExclamationIcon fill={color} width={SizeWidth} height={SizeHeight} />
    </div>
  );
}
